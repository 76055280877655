import React, { useContext } from "react";
import { DumpoutPresentation } from "./Dumpout.presentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useDumpout } from "../../../api/modules/GetDumpout";

export const Dumpout = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const dumpoutQuery = useDumpout();
  const { data: dumpoutListItems, isLoading: isLoading } = dumpoutQuery || {};
  return (
    <DumpoutPresentation
      breadcrumbsRef={breadcrumbsRef}
      isLoading={Boolean(isLoading)}
      list={dumpoutListItems}
      pageTitleRef={pageTitleRef}
    />
  );
};
