import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en/translation.json";
import { convertLanguageJsonToObject } from "./translations";
import { DEFAULT_LANG } from "../constants/global";

convertLanguageJsonToObject(en);

// we can use i18next-http-backend when there'll be more than 1 locale
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  fallbackLng: DEFAULT_LANG,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
