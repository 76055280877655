export const baseUrls = {
  // development: "https://servicedev.nationalonsite.com",
  development: "//nosservice.afdev.com.au",
  staging: "https://api.service.nosappwork.com",
  production: "https://api.service.nationalonsite.com",
};

export const marketingUrls = {
  // development: "http://localhost:3001",
  development: "https://joblogtest.nosappwork.com",
  staging: "https://joblogtest.nosappwork.com",
  production: "https://nationalonsite.com",
};
