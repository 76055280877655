// theme/components/toast.ts
// import { ComponentStyleConfig } from "@chakra-ui/react";

export const ToastStyle = {
  variants: {
    lightGreen: () => ({
      bg: "#F6FEF9",
    }),
  },
};
