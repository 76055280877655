import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postForgotPassword } from "./postForgotPassword";
import { TData, TForgotPasswordBody } from "./types";
import { translations } from "../../../i18n/translations";

export const useForgotPassword = () => {
  const toast = useToast();
  return useMutation<TData, unknown, TForgotPasswordBody>(
    (payload) => postForgotPassword(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.forgot_password_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
