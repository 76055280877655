import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n/i18n";

declare global {
  const google: typeof import("google-one-tap");
}

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
