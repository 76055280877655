import React, { useCallback, useContext } from "react";
import { TeamPresentation } from "./Team.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useTeam } from "../../api/modules/Team";
import { ModalsContext } from "../../contexts/ModalsContext/ModalsContext";
import { useNavigate } from "react-router-dom";

export const Team = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const navigate = useNavigate();
  const teamQuery = useTeam();
  const { data: teamData, isLoading: isLoadingTeam } = teamQuery || {};
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { inviteTeamMember } = useContext(ModalsContext);
  const onClickUser = useCallback(
    (jobId: string | number) => () => {
      navigate(`/user/${jobId}`);
    },
    [navigate]
  );
  return (
    <TeamPresentation
      breadcrumbsRef={breadcrumbsRef}
      isLoading={Boolean(isLoadingTeam || isLoadingStatics)}
      list={teamData || []}
      pageTitleRef={pageTitleRef}
      statics={staticsData}
      onClickUser={onClickUser}
      onInviteModalOpen={inviteTeamMember.onOpen}
    />
  );
};
