export const getTagColor = (jobTypeId: number) => {
  return [
    "yellow",
    "orange",
    "green",
    "cyan",
    "blue",
    "teal",
    "purple",
    "pink",
    "red",
  ][jobTypeId];
};
