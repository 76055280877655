import {
  Flex,
  Modal,
  ModalBody,
  // ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Heading,
  Link,
  Box,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { translations } from "../../i18n/translations";
import TickCircleImg from "../../assets/tick-circle.png";
import PlayStoreImg from "../../assets/google-play.png";
import AppStoreImg from "../../assets/app-store.png";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const StartupModalPresentation = (props: Props) => {
  const { isOpen, onClose } = props;
  const screen = translations.screens.profile.startupModal;
  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent height="582px" width="528px">
          <ModalHeader mt={7} px={10}></ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody mb={5} px={12}>
            <Flex flexDirection="column">
              <Flex justifyContent="center">
                <Image src={TickCircleImg} textAlign="center" width="126px" />
              </Flex>
              <Heading
                color="black"
                fontSize="2xl"
                marginY="34px"
                textAlign="center"
              >
                {t<string>(screen.heading)}
              </Heading>
              <Flex
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
              >
                <Text
                  color="#3C4257"
                  fontSize="18px"
                  fontWeight="400"
                  lineHeight="21.78px"
                >
                  {t<string>(screen.content1)}
                </Text>
                <br />
                <Text
                  color="#3C4257"
                  fontSize="18px"
                  fontWeight="400"
                  lineHeight="21.78px"
                >
                  {t<string>(screen.content2)}
                </Text>
              </Flex>
              <Flex align-items="self-end" marginY="34px">
                <Box>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.nationalonsite.proapp"
                    isExternal
                  >
                    <Image src={PlayStoreImg} />
                  </Link>
                </Box>
                <Box marginTop="4px">
                  <Link
                    href="https://apps.apple.com/us/app/national-onsite/id1625552541"
                    isExternal
                  >
                    <Image src={AppStoreImg}></Image>
                  </Link>
                </Box>
              </Flex>
              <Flex justifyContent="center">
                <Link
                  _hover={{ textDecoration: "none" }}
                  color="#4C37E9"
                  colorScheme="nosPurple"
                  fontSize="18px"
                  fontWeight="500"
                  textDecoration="none"
                  onClick={onClose}
                >
                  {t<string>(screen.continueDashboard)}
                </Link>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
