import { Button, Flex, FormLabel, Textarea } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";
import { t } from "i18next";
import { translations } from "../../../../../../i18n/translations";

type Props = {
  isLoading: boolean;
  onCancelJob: () => void;
  reason: string;
  onChangeReason: (e: ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Details = (props: Props) => {
  const { isLoading, onCancelJob, onChangeReason, reason } = props;
  const screen = translations.screens.cancelJob;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" my={10} px={[3, 3, 3, 55]}>
        <FormLabel htmlFor="reason">
          {t<string>(screen.details.details)}
        </FormLabel>
        <Textarea
          id="reason"
          placeholder={t<string>(screen.details.detailsDescription)}
          // size="sm"
          value={reason}
          onChange={onChangeReason}
        />
      </Flex>
      <Flex justifyContent="center" mb={10} mt={7}>
        <Button
          colorScheme="red"
          isDisabled={!reason}
          isLoading={isLoading}
          size="sm"
          onClick={onCancelJob}
        >
          {t<string>(screen.details.cancel)}
        </Button>
      </Flex>
    </Flex>
  );
};
