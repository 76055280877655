import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUploadProfilePhoto } from "./types";

export const postUploadProfilePhoto = async (payload: TUploadProfilePhoto) => {
  let endpoint = "";
  if (payload.isCompanyLogo) {
    endpoint = Endpoints.postUploadCompanyLogo;
  } else {
    endpoint = Endpoints.postUploadProfilePhoto;
  }
  const data = await client.post<TUploadProfilePhoto, TData>(
    endpoint,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data.userData;
};
