import { Flex, useRadioGroup } from "@chakra-ui/react";
import React from "react";
import { RadioButton } from "../RadioButton";
import { Props } from "./JobsStatusFilters.types";

export const JobsStatusFilters = (props: Props) => {
  const { options, name, onChange, defaultValue } = props;

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();

  return (
    <Flex {...group} gap="10px" wrap="wrap">
      {options.map(({ label, value }) => {
        const radio = getRadioProps({ value });
        return (
          <Flex key={value} py={1}>
            <RadioButton {...radio}>{label}</RadioButton>
          </Flex>
        );
      })}
    </Flex>
  );
};
