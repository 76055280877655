import React from "react";
import { Props } from "./FullPageContent.types";
import Feature from "../Feature";

export const FullPageContent = ({ children, ...rest }: Props) => {
  // full height - header height
  return (
    <Feature minHeight="calc(100vh - 100px)" {...rest}>
      {children}
    </Feature>
  );
};
