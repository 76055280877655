import React from "react";
import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { ContactInformation } from "../ContactInformation";
import { Props } from "./PersonalDetails.types";
import { TradeLicenses } from "../TradeLicenses";

export const PersonalDetailsTab = (props: Props) => {
  const {
    data,
    formData,
    onEditField,
    inAccountWizard,
    isEditMode,
    isEditable,
    isUpdatingProfile,
    isUpdatingProfileNext,
    onCancelEditMode,
    onSaveProfile,
    onSetEditMode,
    onPersonalNext,
    onCancel,
  } = props;
  const screen = translations.screens.profile;

  return (
    <>
      <br />
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Text fontSize="lg" fontWeight={600}>
            {t<string>(screen.personalDetails)}
          </Text>
          <Text>{t<string>(screen.detailsDescription)}</Text>
        </Flex>
      </Flex>
      <br />
      <Divider />
      <ContactInformation
        data={data}
        formData={formData}
        inAccountWizard={inAccountWizard}
        isEditMode={isEditMode}
        isUpdatingProfile={isUpdatingProfile}
        onEditField={onEditField}
      />
      <br />
      <Flex flexDirection="row">
        <Flex flexDirection="column" width="332px">
          <Text fontSize="lg" fontWeight={600}>
            {t<string>(screen.trade)}
          </Text>
          <Text>{t<string>(screen.leaveBlank)}</Text>
        </Flex>
        <TradeLicenses
          data={data}
          formData={formData}
          isEditMode={isEditMode || inAccountWizard}
          isUpdatingProfile={isUpdatingProfile}
          onEditField={onEditField}
        />
      </Flex>
      <Divider />
      <Flex justifyContent="flex-end" mt="20px">
        <Flex>
          {isEditMode && !inAccountWizard && (
            <>
              <Button
                colorScheme="blue"
                isLoading={isUpdatingProfile}
                size="sm"
                variant="outline"
                onClick={onCancelEditMode}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Box ml={3}>
                <Button
                  colorScheme="blue"
                  isLoading={isUpdatingProfile}
                  size="sm"
                  onClick={onSaveProfile}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {!isEditMode && isEditable && !inAccountWizard && (
            <Button colorScheme="blue" size="sm" onClick={onSetEditMode}>
              {t<string>(screen.edit)}
            </Button>
          )}
          {inAccountWizard && (
            <Flex gap={2}>
              {/* <Button
                colorScheme="nosPurple"
                isLoading={isUpdatingProfile}
                size="sm"
                onClick={onSaveProfile}
              >
                {t<string>(screen.save)}
              </Button> */}
              <Button
                colorScheme="nosPurple"
                size="sm"
                variant="outline"
                onClick={onCancel}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Button
                colorScheme="nosPurple"
                isLoading={isUpdatingProfileNext}
                size="sm"
                onClick={onPersonalNext}
              >
                {t<string>(screen.next)}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};
