import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUpdateCompanyBody } from "./types";

export const postUpdateCompany = async (payload: TUpdateCompanyBody) => {
  const data = await client.post<TUpdateCompanyBody, TData>(
    Endpoints.postUpdateCompany,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data.userData;
};
