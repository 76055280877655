import { IconType } from "react-icons";
import {
  IoIosSettings,
  // IoIosCube,
  // IoMdHelpCircle,
  IoMdPerson,
  IoIosBriefcase,
  // IoMdHome,
  // IoMdFlame,
  IoMdPeople,
} from "react-icons/io";
import { PiDropSlashFill } from "react-icons/pi";
import { Routing } from "./routing";
import { Roles } from "./roles";

export enum MenuElement {
  ITEM = "item",
  SECTION = "section",
}

export type MenuItemType = {
  title: string;
  icon?: IconType;
  link: string;
  accessLevel?: string[];
  type: MenuElement.ITEM;
};

export type MenuSectionType = {
  title: string;
  icon: IconType;
  accessLevel?: string[];
  items: MenuItemType[];
  type: MenuElement.SECTION;
};

export type MenuType = (MenuSectionType | MenuItemType)[];

export const GeneralMenuItems: MenuType = [
  // {
  //   title: "common.menu.dashboard",
  //   icon: IoIosCube,
  //   link: Routing.Root,
  //   type: MenuElement.ITEM,
  // },
  {
    title: "common.menu.jobs",
    icon: IoIosBriefcase,
    type: MenuElement.SECTION,
    items: [
      {
        title: "common.menu.jobs",
        // icon: IoMdPeople,
        link: Routing.Jobs,
        type: MenuElement.ITEM,
        // accessLevel: [Roles.CompanyAdmin],
      },
      {
        title: "common.menu.availableJobs",
        // icon: IoMdFlame,
        link: Routing.AvailableJobs,
        type: MenuElement.ITEM,
      },
    ],
  },
  {
    title: "common.menu.pumperPanel",
    icon: PiDropSlashFill,
    type: MenuElement.SECTION,
    items: [
      {
        title: "common.menu.dumpout",
        link: Routing.Dumpout,
        type: MenuElement.ITEM,
      },
      {
        title: "common.menu.truck",
        link: Routing.Truck,
        type: MenuElement.ITEM,
      },
      {
        title: "common.menu.manifests",
        link: Routing.Manifests,
        type: MenuElement.ITEM,
      },
    ],
  },
  {
    title: "common.menu.team",
    icon: IoMdPeople,
    link: Routing.Team,
    accessLevel: [Roles.ContractorAdmin],
    type: MenuElement.ITEM,
  },
];

export const AdditionalMenuItems: MenuType = [
  {
    title: "common.menu.profile",
    icon: IoMdPerson,
    link: Routing.Profile,
    type: MenuElement.ITEM,
  },
  {
    title: "common.menu.settings",
    icon: IoIosSettings,
    link: Routing.Settings.LoginInformation.Absolute,
    type: MenuElement.ITEM,
  },
  // { title: "common.menu.help", icon: IoMdHelpCircle, link: "#" },
];
