import React from "react";
import Layout from "../../../components/Layout";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Portal,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../../constants/routing";
import { translations } from "../../../i18n/translations";
import { PresentationProps } from "./Jobs.types";
import { Link as RouterLink } from "react-router-dom";
import { getAddressCopy } from "../../../utils/Job/getAddressCopy";
import { getScheduleCopy } from "../../../utils/Job/getScheduleCopy";
import { getTagColor } from "../../../utils/Job/getTagColor";
import { getJobTypeCopy } from "../../../utils/Job/getJobTypeCopy";
import { Pagination } from "../../../components/Pagination";
import { PersonName } from "../../../components/PersonName";
import { PropertyIcon } from "../Common/PropertyIcon";
import { STATUS_OPTIONS, allMembersOption } from "./constants";
import FullPageContent from "../../../components/FullPageContent";
import { JobsStatusFilters } from "../../../components/JobsStatusFilters";
import { getTagColorByStatus } from "../../../utils/Job/getTagColorByStatus";
import { AccessLevelGuard } from "../../../components/AccessLevelGuard";
import { Roles } from "../../../constants/roles";
import { Helmet } from "react-helmet";

export const JobsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.jobs;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    jobsData,
    statics,
    pagination,
    status,
    teamMember,
    team,
    onClickJob,
    onChangeTeamMember,
    onChangeStatus,
    onViewAvailableJobs,
  } = props;

  const {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
  } = pagination;

  const { jobs } = jobsData || {};

  return (
    <Layout>
      <Helmet>
        <title>National Onsite</title>
      </Helmet>
      <Portal containerRef={pageTitleRef}>{t<string>(screen.pageTitle)}</Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.breadcrumbs.page)}</div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <FullPageContent>
        <Flex flex={1} flexDirection="column">
          <Flex justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button colorScheme="blue" size="sm" onClick={onViewAvailableJobs}>
              {t<string>(screen.viewAvailableJobs)}
            </Button>
          </Flex>

          <br />
          <Flex
            flexDirection={["column", "column", "column", "row"]}
            gap="40px"
          >
            <Flex flexBasis={["100%", "100%", "100%", "50%"]}>
              <JobsStatusFilters
                defaultValue={status}
                name="status"
                options={STATUS_OPTIONS}
                onChange={onChangeStatus}
              />
            </Flex>
            <AccessLevelGuard permittedRoles={[Roles.ContractorAdmin]}>
              <Flex
                alignItems="center"
                flexBasis="50%"
                justifyContent="flex-end"
              >
                <Flex mr={4}>
                  <Text fontSize="sm" fontWeight={500}>
                    {t<string>(screen.showingJobsFor)}
                  </Text>
                </Flex>
                <Select
                  maxWidth="300px"
                  value={teamMember}
                  onChange={onChangeTeamMember}
                >
                  {[allMembersOption, ...(team || [])].map(
                    ({ userId, fullName }) => (
                      <option key={userId + fullName} value={userId}>
                        {fullName}
                      </option>
                    )
                  )}
                </Select>
              </Flex>
            </AccessLevelGuard>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t<string>(screen.table.property)}</Th>
                    <Th>{t<string>(screen.table.jobType)}</Th>
                    <Th>{t<string>(screen.table.schedule)}</Th>
                    <Th>{t<string>(screen.table.contact)}</Th>
                    <Th>{t<string>(screen.table.price)}</Th>
                    <Th>{t<string>(screen.table.status)}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(jobs || []).map((job) => (
                    <Tr
                      key={job.jobId}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={onClickJob(job.jobId)}
                    >
                      <Td fontWeight={500}>
                        <Flex alignItems="center" width="250px">
                          <PropertyIcon propertyUse={job.propertyUse} />
                          <Flex ml={2} whiteSpace="break-spaces">
                            {getAddressCopy(job)}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td>
                        {job.jobTypeId && (
                          <Tag colorScheme={getTagColor(job.jobTypeId)}>
                            <Text size="12px">
                              {getJobTypeCopy(job, statics).jobType}
                            </Text>
                          </Tag>
                        )}
                      </Td>
                      <Td>{getScheduleCopy(job, statics)}</Td>
                      <Td>
                        {(job.propertyContacts || []).length
                          ? (job.propertyContacts || []).map((contact) => (
                              <PersonName
                                key={contact.emailAddress}
                                email={contact.emailAddress}
                                firstName={contact.firstName}
                                lastName={contact.lastName}
                              />
                            ))
                          : "-"}
                      </Td>
                      <Td>${job.totalAmountPaid}</Td>
                      <Td>
                        <Tag
                          borderRadius="sm"
                          colorScheme={getTagColorByStatus(job.jobStatus)}
                          fontWeight={600}
                          variant="solid"
                        >
                          {job.jobStatus.toUpperCase()}
                        </Tag>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(jobs || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noJobs)}</Text>
            </Flex>
          )}
        </Flex>
        <Pagination
          currentPage={currentPage}
          isNextDisabled={isNextDisabled}
          isPrevDisabled={isPrevDisabled}
          itemsPerPage={itemsPerPage}
          mt={4}
          totalPages={totalPages}
          onChangeItemsPerPage={onChangeItemsPerPage}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      </FullPageContent>
    </Layout>
  );
};
