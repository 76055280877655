import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postUpdateCompany } from "./postUpdateCompany";
import { TUpdateCompanyBody } from "./types";
import { translations } from "../../../i18n/translations";
import { Queries } from "../../../constants/queries";

export const useUpdateCompany = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, TUpdateCompanyBody>(
    (payload) => postUpdateCompany(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.update_company_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(Queries.User);
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["company"]);
      },
    }
  );
};
