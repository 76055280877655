import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getDumpingLogs } from "./getDumpingLogs";
import { TDumpingLogs } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

type Props = {
  companyId?: number | null;
  startDate: string;
  endDate: string;
};

export const useDumpingLogs = ({ companyId, startDate, endDate }: Props) => {
  const toast = useToast();

  return useQuery<TDumpingLogs>(
    [Queries.Manifests, companyId, startDate, endDate],
    getDumpingLogs(companyId!, startDate, endDate),
    {
      enabled: !!companyId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.jobs_error),
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
    }
  );
};
