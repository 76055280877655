export const nosPurple = {
  50: "#FAF9FF",
  100: "#F8F5FF",
  200: "#E0D8FF",
  300: "#C2B5FF",
  400: "#A393FF",
  500: "#4C37E9",
  600: "#5B4FE0",
  700: "#4C37E9",
  800: "#291CA7",
  900: "#100B6F",
  //   100: "#F8F5FF",
  //   700: "#4C37E9",
  //   800: "#291CA7",
  //   900: "#100B6F",
};
