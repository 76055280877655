import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TAcceptJob, TPayload } from "./types";

export const postAcceptJob = async ({ jobId }: TPayload) => {
  const { data } = await client.post<unknown, TAcceptJob>(
    Endpoints.postAcceptJob(jobId),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
