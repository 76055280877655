import { baseUrls } from "../constants/baseUrls";

export const getBaseUrl = () => {
  const { development, staging, production } = baseUrls;
  switch (process.env.REACT_APP_API_ENV) {
    case "production":
      return production;
    case "staging":
      return staging;
    case "dev":
    default:
      return development;
  }
};
