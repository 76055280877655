import React from "react";
import Layout from "../../../components/Layout";
import { FiPlus as PlusIcon } from "react-icons/fi";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../../constants/routing";
import { translations } from "../../../i18n/translations";
import { PresentationProps } from "./Truck.types";
import { Link as RouterLink } from "react-router-dom";
import FullPageContent from "../../../components/FullPageContent";
import { BiEditAlt as EditIcon } from "react-icons/bi";

export const TruckPresentation = (props: PresentationProps) => {
  const screen = translations.screens.pumperPanel.truck;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    list,
    onEditClick,
    onInviteModalOpen,
  } = props;

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.page)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button
              colorScheme="blue"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={onInviteModalOpen}
            >
              {t<string>(screen.add)}
            </Button>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}
          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="left">
                      {t<string>(screen.table.truckName)}
                    </Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.licenceplate)}
                    </Th>
                    <Th textAlign="left">{t<string>(screen.table.make)}</Th>
                    <Th textAlign="left">{t<string>(screen.table.model)}</Th>
                    <Th textAlign="left">{t<string>(screen.table.capacity)}</Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.pumpingLicense)}
                    </Th>
                    <Th textAlign="center"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(list || []).map((item) => (
                    <Tr
                      key={item.truckId}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Td textAlign="left">{item.assetName}</Td>
                      <Td textAlign="left">{item.licensePlate}</Td>
                      <Td textAlign="left">{item.make}</Td>
                      <Td textAlign="left">{item.model}</Td>
                      <Td textAlign="left">{item.capacity}</Td>
                      <Td textAlign="left">{item.pumpingLicenceNumber}</Td>
                      <Td textAlign="center">
                        <EditIcon
                          fontSize="24px"
                          fontWeight={400}
                          onClick={() => onEditClick(item.truckId)}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && list !== undefined && !(list || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noTruck)}</Text>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
    </>
  );
};
