import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUpdateCompanyJobTypesBody } from "./types";

export const postUpdateCompanyJobTypes = async (
  payload: TUpdateCompanyJobTypesBody,
  companyId: number
) => {
  const data = await client.post<TUpdateCompanyJobTypesBody, TData>(
    Endpoints.postUpdateCompanyJobTypes(companyId),
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
