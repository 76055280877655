import React, { useCallback, useContext, useEffect, useState } from "react";
import { UpdateRolesSteps } from "./UpdateRoles.types";
import { ModalsContext } from "../../../contexts/ModalsContext";
import { UpdateRolesPresentation } from "./UpdateRoles.presentation";
import { useStatics } from "../../../api/modules/Statics";
import { useUpdateUserById } from "../../../api/modules/UpdateUserById";
import { useUserById } from "../../../api/modules/UserById";

type Props = {
  userId?: number;
};

export const UpdateRoles = (props: Props) => {
  const { updateRoles } = useContext(ModalsContext);
  const [step, setStep] = useState<UpdateRolesSteps>(UpdateRolesSteps.Details);
  const { mutateAsync: handleRequestUpdateUser, isLoading: isUpdating } =
    useUpdateUserById();
  const { data, isLoading: isLoadingUser } = useUserById(props.userId);
  const [roles, setRoles] = useState<string[]>([]);
  const { data: staticsData } = useStatics();
  useEffect(() => {
    setRoles(data?.roles || []);
  }, [data]);

  const handleCloseModal = useCallback(() => {
    updateRoles.onClose();
    setStep(UpdateRolesSteps.Details);
  }, [updateRoles]);

  const handleChangeRoles = useCallback(() => {
    if (!props.userId || !roles.length) return;
    const rolesIds = roles.reduce<number[]>((acc, roleName) => {
      const role = staticsData?.roles.find((r) => r.roleName === roleName);
      if (role) {
        acc.push(role.roleId);
      }
      return acc;
    }, []);
    handleRequestUpdateUser(
      {
        userId: props.userId,
        roles: rolesIds,
      },
      {
        onSuccess: () => {
          setStep(UpdateRolesSteps.Result);
        },
      }
    );
  }, [staticsData, handleRequestUpdateUser, roles, setStep, props.userId]);

  const handleChangeRole = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      if (!checked) {
        setRoles(roles.filter((role) => role !== value));
        return;
      }
      setRoles([...roles, value]);
    },
    [roles, setRoles]
  );

  return (
    <UpdateRolesPresentation
      isLoadingUser={isLoadingUser}
      isOpen={updateRoles.isOpen}
      isUpdating={isUpdating}
      roles={roles}
      statics={staticsData}
      step={step}
      userData={data}
      onChangeRole={handleChangeRole}
      onClose={handleCloseModal}
      onUpdate={handleChangeRoles}
    />
  );
};
