import React, { useCallback } from "react";
import { AcceptInvitationPresentation } from "./AcceptInvitation.presentation";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { FormType } from "./AcceptInvitation.types";
import { useAcceptInvitation } from "../../api/modules/AcceptInvitation";
import { CredentialResponse } from "google-one-tap";
import { useInitializeGoogleAuth } from "../../hooks/useInitializeGoogleAuth";
import { useInitializeAppleAuth } from "../../hooks/useInitializeAppleAuth";

export const AcceptInvitation = () => {
  const { mutateAsync } = useAcceptInvitation();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm<FormType>({
    mode: "onChange",
  });

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync(
      {
        email: data.email,
        password: data.password,
        inviteCode: data.inviteCode,
      },
      {
        onSuccess: () => {
          navigate(Routing.Root);
        },
      }
    );
  });

  const handleGoogleSignIn = useCallback(
    (response: CredentialResponse) => {
      if (!response.credential) return;
      const inviteCode = getValues("inviteCode");
      mutateAsync(
        { token: response.credential, inviteCode },
        {
          onSuccess: () => {
            navigate(Routing.Root);
          },
        }
      );
    },
    [navigate, mutateAsync, getValues]
  );

  useInitializeGoogleAuth({
    callback: handleGoogleSignIn,
  });
  useInitializeAppleAuth();

  const handleAppleSignIn = useCallback(async () => {
    const response = await AppleID.auth.signIn();
    if (!response?.authorization?.id_token) return;
    const inviteCode = getValues("inviteCode");
    mutateAsync(
      { token: response.authorization.id_token, inviteCode },
      {
        onSuccess: () => {
          navigate(Routing.Root);
        },
      }
    );
  }, [navigate, mutateAsync, getValues]);

  return (
    <AcceptInvitationPresentation
      errors={errors}
      isSubmitting={isSubmitting}
      isValid={isValid}
      register={register}
      onAppleSignIn={handleAppleSignIn}
      onSubmit={onSubmit}
    />
  );
};
