import { revenueCatKey } from "../constants/revenueCatKey";

export const getRevenueCatKey = () => {
  const { development, staging, production } = revenueCatKey;
  switch (process.env.REACT_APP_API_ENV) {
    case "production":
      return production;
    case "staging":
      return staging;
    case "dev":
    default:
      return development;
  }
};
