import { Box, useRadio } from "@chakra-ui/react";
import { Props } from "./RadioButton.types";
import React from "react";

export const RadioButton = (props: Props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        _checked={{
          bg: "gray.700",
          color: "white",
          borderColor: "gray.700",
        }}
        borderRadius="3xl"
        borderWidth="1px"
        cursor="pointer"
        fontSize="sm"
        fontWeight={500}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};
