import React from "react";
import { ForgotPasswordPresentation } from "./ForgotPassword.presentation";
import { useForm } from "react-hook-form";
import { ForgotPasswordFormType } from "./ForgotPassword.types";
import { useForgotPassword } from "../../api/modules/ForgotPassword";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";

export const ForgotPassword = () => {
  const { mutateAsync } = useForgotPassword();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ForgotPasswordFormType>();

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsync(
      {
        email: data.email,
      },
      {
        onSuccess: () => {
          toast({
            title: "Success",
            description: t<string>(
              translations.screens.forgotPassword.requestSuccess
            ),
            status: "info",
            position: "top-right",
          });
          navigate(Routing.Root);
        },
      }
    );
  });

  return (
    <ForgotPasswordPresentation
      errors={errors}
      isSubmitting={isSubmitting}
      isValid={isValid}
      register={register}
      onSubmit={onSubmit}
    />
  );
};
