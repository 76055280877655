import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TInviteTeamMember, TInviteTeamMemberBody } from "./types";

export const postInviteTeamMember = async (payload: TInviteTeamMemberBody) => {
  const { data } = await client.post<TInviteTeamMemberBody, TInviteTeamMember>(
    Endpoints.postInviteTeamMember,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
