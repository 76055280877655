import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { postInviteTeamMember } from "./postAcceptInvitation";
import { TData, TInviteTeamMemberBody } from "./types";
import { translations } from "../../../i18n/translations";

export const useInviteTeamMember = () => {
  const toast = useToast();
  return useMutation<TData, unknown, TInviteTeamMemberBody>(
    (payload) => postInviteTeamMember(payload),
    {
      onError: (err) => {
        const message = (err as Error)?.message;
        if (message === "No available seats left") {
          toast({
            title: "Invite Unsuccessful",
            description:
              (err as Error)?.message ||
              t<string>(translations.errors.invite_team_member_error),
            status: "warning",
            position: "top-right",
            isClosable: true,
          });
        } else if (message === "Company has no subscription") {
          toast({
            title: "Invite Unsuccessful",
            description:
              (err as Error)?.message ||
              t<string>(translations.errors.invite_team_member_error),
            status: "warning",
            position: "top-right",
            isClosable: true,
          });
        } else if (message === "User email already exists") {
          toast({
            title: "Invite Unsuccessful",
            description:
              (err as Error)?.message ||
              t<string>(translations.errors.invite_team_member_error),
            status: "warning",
            position: "top-right",
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: t<string>(
              translations.errors.invite_team_member_error
            ),
            status: "error",
            position: "top-right",
          });
        }
      },
    }
  );
};
