import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getUserById } from "./getUserById";
import { TUser } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

export const useUserById = (userId?: string | number) => {
  const toast = useToast();
  return useQuery<TUser>(
    Queries.UserById(userId ? +userId : undefined),
    getUserById(userId!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.user_error),
          status: "error",
          position: "top-right",
        });
      },
      enabled: Boolean(userId),
    }
  );
};
