export const getTagColorByStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case "available":
      return "green";
    case "accepted":
      return "blue";
    case "in progress":
    case "in-progress":
      return "orange";
    case "paid":
      return "teal";
    case "completed":
      return "teal";
    case "cancelled":
      return "red";
  }
  return "gray";
};
