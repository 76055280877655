import { Endpoints } from "../../../constants/endpoints";
import { TDecryptTokenBody, TDecryptTokenRaw } from "./types";
import client from "../../client";

export const postDecryptToken = async (token: TDecryptTokenBody) => {
  const encodedToken = encodeURIComponent(token.token);
  const { data } = await client.post<TDecryptTokenBody, TDecryptTokenRaw>(
    Endpoints.postDecryptToken(),
    {
      token: encodedToken || "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
