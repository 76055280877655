import { ComponentStyleConfig } from "@chakra-ui/react";

export const Table: ComponentStyleConfig = {
  parts: ["table", "thead", "tbody", "tfoot", "tr", "th", "td"],
  baseStyle: {
    table: {},
    thead: {
      background: "gray.50",
    },
    tbody: {},
    tfoot: {},
    tr: {},
    th: {
      "font-size": "10px!important",
      borderBottomColor: "gray.200!important",
    },
    td: {
      color: "gray.700",
      fontSize: "sm",
      borderBottomColor: "gray.200!important",
    },
  },
  variants: {
    unstyled: {
      td: {
        paddingLeft: "0!important",
        paddingRight: "0!important",
      },
    },
  },
  sizes: {
    sm: {
      td: {
        padding: "14px!important",
      },
    },
  },
};
