import React from "react";
import Layout from "../../../components/Layout";
import { FiPlus as PlusIcon } from "react-icons/fi";
import {
  FiDownloadCloud as CloudIcon,
  FiArrowRight as OpenIcon,
} from "react-icons/fi";

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Image,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../../constants/routing";
import { translations } from "../../../i18n/translations";
import { PresentationProps } from "./Manifests.types";
import { Link as RouterLink } from "react-router-dom";
import FullPageContent from "../../../components/FullPageContent";
import ReportImg from "../../../assets/pdficon.png";
import { format } from "date-fns";
import { MONTH_DATE_FORMAT } from "../../../constants/global";

export const ManifestsPresentation = (props: PresentationProps) => {
  const screen = translations.screens.pumperPanel.manifests;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isDownloading,
    isLoading,
    list,
    onInviteModalOpen,
    onDownloadClick,
    onPdfNewTabClick,
  } = props;

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.page)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button
              colorScheme="blue"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={onInviteModalOpen}
            >
              {t<string>(screen.add)}
            </Button>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}

          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t<string>(screen.table.manifest)}</Th>
                    <Th>{t<string>(screen.table.createdBy)}</Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.dateCreated)}
                    </Th>
                    <Th textAlign="left">{t<string>(screen.table.action)}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(list?.companyDumpingLogs || []).map((item) => (
                    <Tr
                      key={item.createdDate}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Td>
                        <Flex flexDirection="row" gap={1}>
                          <Image src={ReportImg} width="40px" />
                          <Box mt="10px">
                            {format(item.startDate, MONTH_DATE_FORMAT)} to{" "}
                            {format(item.endDate, MONTH_DATE_FORMAT)}
                          </Box>
                        </Flex>
                      </Td>
                      <Td>{item.createdBy}</Td>
                      <Td textAlign="left">
                        {format(item.createdDate, "MMM dd, yyyy HH:mm")}
                      </Td>
                      <Td textAlign="left">
                        <Flex flexDirection="row" gap={5}>
                          <CloudIcon
                            style={{
                              cursor: isDownloading ? "not-allowed" : "pointer",
                            }}
                            onClick={() => onDownloadClick(item.reportUrl)}
                          />
                          <OpenIcon
                            onClick={onPdfNewTabClick(item.reportUrl)}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(list?.companyDumpingLogs || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noManifest)}</Text>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
    </>
  );
};
