import React, { useCallback, useEffect } from "react";
import {
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Props } from "./PersonalJobs.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { useJobsByUserId } from "../../../../api/modules/JobsByUserId";
import { usePagination } from "../../../../hooks/usePagination";
import { getAddressCopy } from "../../../../utils/Job/getAddressCopy";
import { getTagColor } from "../../../../utils/Job/getTagColor";
import { getJobTypeCopy } from "../../../../utils/Job/getJobTypeCopy";
import { getScheduleCopy } from "../../../../utils/Job/getScheduleCopy";
import { PersonName } from "../../../../components/PersonName";
import { Pagination } from "../../../../components/Pagination";
import { useStatics } from "../../../../api/modules/Statics";
import { useNavigate } from "react-router-dom";
import { PropertyIcon } from "../../../Jobs/Common/PropertyIcon";
import { getTagColorByStatus } from "../../../../utils/Job/getTagColorByStatus";

export const PersonalJobs = (props: Props) => {
  const navigate = useNavigate();
  const screen = translations.screens.profile.jobsDetails;
  const { data } = props;
  const { userId } = data || {};
  const pagination = usePagination();
  const { data: statics } = useStatics();
  const { isLoading, data: jobsData } = useJobsByUserId({
    userId: userId || 0,
    itemsPerPage: pagination.itemsPerPage,
    currentPage: pagination.currentPage,
  });

  useEffect(() => {
    pagination.setTotalItems(jobsData?.totalJobsCount ?? 0);
  }, [pagination, jobsData?.totalJobsCount]);

  const {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
  } = pagination;

  const onClickJob = useCallback(
    (jobId: string | number) => () => {
      navigate(`/job/${jobId}`);
    },
    [navigate]
  );

  return (
    <Flex flex={1} flexDirection="column">
      <Flex flex={1} flexDirection="column">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>{t<string>(screen.table.property)}</Th>
                <Th>{t<string>(screen.table.jobType)}</Th>
                <Th>{t<string>(screen.table.schedule)}</Th>
                <Th>{t<string>(screen.table.contact)}</Th>
                <Th>{t<string>(screen.table.price)}</Th>
                <Th>{t<string>(screen.table.status)}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(jobsData?.jobs || []).map((job) => (
                <Tr
                  key={job.jobId}
                  _hover={{
                    backgroundColor: "gray.50",
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={onClickJob(job.jobId)}
                >
                  <Td>
                    <Flex alignItems="center" width="250px">
                      <PropertyIcon propertyUse={job.propertyUse} />
                      <Flex ml={2} whiteSpace="break-spaces">
                        {getAddressCopy(job)}
                      </Flex>
                    </Flex>
                  </Td>
                  <Td>
                    {job.jobTypeId && (
                      <Tag colorScheme={getTagColor(job.jobTypeId)}>
                        <Text size="12px">
                          {getJobTypeCopy(job, statics).jobType}
                        </Text>
                      </Tag>
                    )}
                  </Td>
                  <Td>{getScheduleCopy(job, statics)}</Td>
                  <Td>
                    {(job.propertyContacts || []).length
                      ? (job.propertyContacts || []).map((contact) => (
                          <PersonName
                            key={contact.emailAddress}
                            email={contact.emailAddress}
                            firstName={contact.firstName}
                            lastName={contact.lastName}
                          />
                        ))
                      : "-"}
                  </Td>
                  <Td>${job.totalAmountPaid}</Td>
                  <Td>
                    <Tag
                      borderRadius="sm"
                      colorScheme={getTagColorByStatus(job.jobStatus)}
                      fontWeight={600}
                      variant="solid"
                    >
                      {job.jobStatus.toUpperCase()}
                    </Tag>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {isLoading && (
          <Flex justifyContent="center" mt={4}>
            <Spinner />
          </Flex>
        )}
        {!isLoading && !(jobsData?.jobs || []).length && (
          <Flex justifyContent="center" mt={4}>
            <Text fontWeight={500}>{t<string>(screen.noJobs)}</Text>
          </Flex>
        )}
      </Flex>
      <Pagination
        currentPage={currentPage}
        isNextDisabled={isNextDisabled}
        isPrevDisabled={isPrevDisabled}
        itemsPerPage={itemsPerPage}
        mt={4}
        totalPages={totalPages}
        onChangeItemsPerPage={onChangeItemsPerPage}
        onClickNext={onClickNext}
        onClickPrev={onClickPrev}
      />
    </Flex>
  );
};
