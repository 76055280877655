import { JobStatus } from "../../../api/modules/Jobs/types";

export const STATUS_OPTIONS = [
  {
    label: "Scheduled",
    value: JobStatus.Accepted,
  },
  {
    label: "In Progress",
    value: JobStatus.InProgress,
  },
  {
    label: "Completed",
    value: JobStatus.Completed,
  },
  {
    label: "Cancelled",
    value: JobStatus.Cancelled,
  },
];

export const allMembersOption = {
  fullName: "All Team Members",
  userId: undefined,
};
