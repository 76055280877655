import { Routing } from "../../constants/routing";
import { Navigate, Outlet } from "react-router-dom";
import * as React from "react";

import { getAccessToken } from "../../utils/getAccessToken";

export const ProtectedRoutes = (): React.ReactElement | null => {
  const isAuthenticated = Boolean(getAccessToken());
  if (!isAuthenticated) {
    return <Navigate to={Routing.Login} />;
  }
  return <Outlet />;
};
