import React, { useCallback, useContext } from "react";
import { LoginInformationPresentation } from "./LoginInformation.presentation";
import { useUser } from "../../../api/modules/User";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useForm } from "react-hook-form";
import { FormType } from "./LoginInformation.types";
import { useUpdatePassword } from "../../../api/modules/UpdatePassword";
import { VALIDATION_RULES } from "../../../components/PasswordValidator/constants";

export const LoginInformation = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { data: userData, isLoading } = useUser();

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields, isValid },
    watch,
  } = useForm<FormType>({
    mode: "onChange",
  });
  const { mutateAsync: mutateAsyncInvite, isLoading: isLoadingUpdatePassword } =
    useUpdatePassword();
  const currentPassword = watch("password");

  const onSubmit = handleSubmit(async (data) => {
    await mutateAsyncInvite({
      password: data.password,
    });
  });

  const validatePassword = useCallback((value: string | null) => {
    return !Object.values(VALIDATION_RULES).some(
      (regex) => !regex.test(value || "")
    );
  }, []);

  const validateConfirmPassword = useCallback(
    (value: string | null) => {
      return value === currentPassword;
    },
    [currentPassword]
  );

  return (
    <LoginInformationPresentation
      breadcrumbsRef={breadcrumbsRef}
      currentPassword={currentPassword}
      errors={errors}
      isChangePasswordDisabled={!isValid}
      isLoading={isLoading || isLoadingUpdatePassword}
      pageTitleRef={pageTitleRef}
      register={register}
      touchedFields={touchedFields}
      user={userData}
      validateConfirmPassword={validateConfirmPassword}
      validatePassword={validatePassword}
      onSubmit={onSubmit}
    />
  );
};
