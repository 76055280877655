import { Endpoints } from "../../../constants/endpoints";
import { TJobsRaw } from "./types";
import client from "../../client";

export const getJobsByStatus =
  (
    currentPage: number,
    itemsPerPage: number,
    status: string,
    companyId: number,
    contractorId?: number
  ) =>
  async () => {
    const { data } = await client.get<TJobsRaw>(
      Endpoints.getJobsByStatus(
        currentPage,
        itemsPerPage,
        status,
        companyId,
        contractorId
      )
    );
    return data;
  };
