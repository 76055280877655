import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TRegisterUser, TForgotPasswordBody } from "./types";

export const postForgotPassword = async (payload: TForgotPasswordBody) => {
  const { data } = await client.post<TForgotPasswordBody, TRegisterUser>(
    Endpoints.postForgotPassword,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
