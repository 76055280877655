import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { UpdateRolesSteps } from "./UpdateRoles.types";
import { translations } from "../../../i18n/translations";
import { TStatics } from "../../../api/modules/Statics/types";
import { getRolesDisplayNames } from "../../../utils/User/getRolesLabel";
import { TUser } from "../../../api/modules/UserById/types";

type Props = {
  isOpen: boolean;
  isLoadingUser: boolean;
  isUpdating: boolean;
  onClose: () => void;
  onUpdate: () => void;
  userData?: TUser;
  onChangeRole: (event: React.ChangeEvent<HTMLInputElement>) => void;
  step: UpdateRolesSteps;
  statics?: TStatics;
  roles: string[];
};

export const UpdateRolesPresentation = (props: Props) => {
  const {
    isOpen,
    isUpdating,
    isLoadingUser,
    statics,
    roles,
    step,
    userData,
    onClose,
    onUpdate,
    onChangeRole,
  } = props;
  const screen = translations.screens.changeRoles;
  const { firstName, lastName } = userData || {};

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={7} px={10}>
            {step === UpdateRolesSteps.Details && (
              <Flex justifyContent="center">
                <Text fontSize="xl">{t<string>(screen.details.title)}</Text>
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={12}>
            {isLoadingUser ? (
              <Flex justifyContent="center" my={4}>
                <Spinner />
              </Flex>
            ) : (
              <>
                {step === UpdateRolesSteps.Details && (
                  <Flex flexDirection="column">
                    {statics?.roles.map((role) => (
                      <Flex key={role.roleId} mb={3}>
                        <Checkbox
                          isChecked={(roles || []).includes(role.roleName)}
                          name="roles"
                          value={role.roleName}
                          onChange={onChangeRole}
                        >
                          {role.displayName}
                        </Checkbox>
                      </Flex>
                    ))}
                    <Flex justifyContent="center" mt={5}>
                      <Button
                        colorScheme="blue"
                        isDisabled={!props.roles.length || isUpdating}
                        isLoading={isUpdating}
                        size="sm"
                        onClick={onUpdate}
                      >
                        {t<string>(screen.details.update)}
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {step === UpdateRolesSteps.Result && (
                  <Flex flexDirection="column">
                    <Text fontSize="xl" fontWeight={600} textAlign="center">
                      {t<string>(screen.resultScreen.title)}
                    </Text>
                    <br />
                    <Text>
                      {t<string>(screen.resultScreen.description, {
                        name: `${firstName || ""} ${lastName || ""}`,
                        roles: getRolesDisplayNames(roles, statics).join(", "),
                      })}
                    </Text>
                    <br />
                  </Flex>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
