import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import {
  AdditionalDataFormType,
  InviteFormType,
  InviteSteps,
} from "./InviteTeamMemberModal.types";
import { UseFormRegister } from "react-hook-form";
import { InviteForm } from "./components/InviteForm";
import { TStatics } from "../../api/modules/Statics/types";
import { AdditionalDataForm } from "./components/AdditionalDataForm";
import { ResultStep } from "./components/ResultStep";

type Props = {
  createdUserFirstName: string;
  isOpen: boolean;
  isLoadingAddDataRequest: boolean;
  isLoadingInviteRequest: boolean;
  onClose: () => void;
  onAddData: () => void;
  onInvite: () => void;
  step: InviteSteps;
  inviteErrors: FieldErrors<InviteFormType>;
  inviteRegister: UseFormRegister<InviteFormType>;
  addDataErrors: FieldErrors<AdditionalDataFormType>;
  addDataRegister: UseFormRegister<AdditionalDataFormType>;
  rolesOptions?: TStatics["roles"];
};

export const InviteTeamMemberModalPresentation = (props: Props) => {
  const {
    createdUserFirstName,
    addDataErrors,
    addDataRegister,
    isOpen,
    isLoadingInviteRequest,
    isLoadingAddDataRequest,
    inviteRegister,
    inviteErrors,
    rolesOptions,
    step,
    onAddData,
    onClose,
    onInvite,
  } = props;
  const screen = translations.screens.inviteTeamMember;
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={7} px={10} textAlign="center">
            {step !== InviteSteps.Result && (
              <Text fontSize="lg">
                {t<string>(
                  screen[InviteSteps.InviteForm ? "title" : "inviteSent"]
                )}
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={12}>
            {step === InviteSteps.Result ? (
              <ResultStep firstName={createdUserFirstName} />
            ) : (
              <>
                {step === InviteSteps.InviteForm && (
                  <>
                    <Box mb={5} textAlign="center">
                      <Text fontSize="sm">{t<string>(screen.description)}</Text>
                    </Box>
                    <InviteForm
                      errors={inviteErrors}
                      isLoading={isLoadingInviteRequest}
                      register={inviteRegister}
                      rolesOptions={rolesOptions}
                      onSubmit={onInvite}
                    />
                  </>
                )}
                {step === InviteSteps.AdditionalData && (
                  <>
                    <Box mb={5} textAlign="center">
                      <Text fontSize="sm">
                        {t<string>(screen.descriptionSent)}
                      </Text>
                    </Box>
                    <AdditionalDataForm
                      errors={addDataErrors}
                      isLoading={isLoadingAddDataRequest}
                      register={addDataRegister}
                      onSubmit={onAddData}
                    />
                  </>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
