import React from "react";
import GoogleMapReact, { Coords } from "google-map-react";
import { Flex, Spinner } from "@chakra-ui/react";
import { Props } from "./Map.types";
import { mapOptions } from "./config";
import { Marker } from "../Marker";

export const Map = ({
  params: { lat, lng },
  jobs,
  apiKey,
  isLoadingData,
  isLoadingProfile,
  onClickJob,
}: Props) => {
  const center: Coords = {
    lat: lat ?? 0,
    lng: lng ?? 0,
  };
  const onChildClick = (id: number) => {
    onClickJob(id)();
  };

  return (
    <Flex height="600px" position="relative" width="100%">
      {isLoadingData && (
        <Flex
          alignItems="center"
          backgroundColor="rgba(0,0,0,0.2)"
          height="100%"
          justifyContent="center"
          position="absolute"
          width="100%"
          zIndex={2}
        >
          <Spinner />
        </Flex>
      )}
      {!isLoadingProfile && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          defaultZoom={12}
          options={mapOptions}
          onChildClick={onChildClick}
        >
          {(jobs || []).map((job) => {
            return (
              <Marker
                key={job.jobId}
                jobTypeId={job.jobTypeId}
                lat={job.lat}
                lng={job.lng}
              />
            );
          })}
        </GoogleMapReact>
      )}
    </Flex>
  );
};
