import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getTeam } from "./getTeam";
import { TTeam } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { useUser } from "../User";

export const useTeam = () => {
  const toast = useToast();
  const { data } = useUser();

  return useQuery<TTeam>(
    Queries.Team(data?.companyId),
    getTeam(data?.companyId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.jobs_error),
          status: "error",
          position: "top-right",
        });
      },
      enabled: !!data && !!data?.companyId,
    }
  );
};
