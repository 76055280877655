import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { ChangeEvent } from "react";
import { ResultScreen } from "./components/ResultScreen";
import { CancelJobSteps } from "./CancelJobModal.types";
import { translations } from "../../../../i18n/translations";
import { Details } from "./components/Details";

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  reason: string;
  onChangeReason: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClose: () => void;
  onCancelJob: () => void;
  step: CancelJobSteps;
};

export const CancelJobModalPresentation = (props: Props) => {
  const {
    isLoading,
    isOpen,
    onClose,
    onCancelJob,
    step,
    reason,
    onChangeReason,
  } = props;
  const screen = translations.screens.cancelJob;

  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="1200px">
          <ModalHeader mt={9} px={10}>
            <Flex alignItems="center" justifyContent="center">
              {step === CancelJobSteps.Details && (
                <Flex flexDirection="column">
                  <Text fontSize="xl" fontWeight={700}>
                    {t<string>(screen.details.title)}
                  </Text>
                </Flex>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={12}>
            {step === CancelJobSteps.Details && (
              <Details
                isLoading={isLoading}
                reason={reason}
                onCancelJob={onCancelJob}
                onChangeReason={onChangeReason}
              />
            )}
            {step === CancelJobSteps.Result && <ResultScreen />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
