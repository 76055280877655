import React, { useContext } from "react";
import { JobPresentation } from "./Job.presentation";
import { PortalsContext } from "../../contexts/PortalsContext";
import { useStatics } from "../../api/modules/Statics";
import { useJob } from "../../api/modules/Job";
import { useParams } from "react-router-dom";
import { AssignJob } from "../AssignJob";
import { ModalsContext } from "../../contexts/ModalsContext";
import { CancelJobModal } from "./components/CancelJobModal";

export const Job = () => {
  const { jobId } = useParams();
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { isLoading: isLoadingJobs, data: jobData } = useJob(+jobId!);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { assignJob, cancelJob } = useContext(ModalsContext);

  return (
    <>
      <JobPresentation
        assignJobModal={assignJob}
        breadcrumbsRef={breadcrumbsRef}
        cancelJobModal={cancelJob}
        isLoading={isLoadingJobs || isLoadingStatics}
        // jobsList={jobsData}
        job={jobData}
        pageTitleRef={pageTitleRef}
        statics={staticsData}
      />
      <AssignJob
        isReassignMode={true}
        jobId={jobData?.jobId}
        preselectedUserId={jobData?.contractor?.userId}
      />
      <CancelJobModal jobId={jobData?.jobId} />
    </>
  );
};
