import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../constants/routing";
import { ModalsContext } from "../../contexts/ModalsContext";
import { StartupModalPresentation } from "./StartupModal.presentation";

export const StartupModal = () => {
  const { startupModal } = useContext(ModalsContext);
  const navigate = useNavigate();
  const getProfileSetupPath = () => {
    return `${Routing.Profile}?account-setup=${true}`;
  };

  const handleCloseModal = useCallback(() => {
    startupModal.onClose();
    navigate(getProfileSetupPath());
  }, [navigate, startupModal]);

  return (
    <StartupModalPresentation
      isOpen={startupModal.isOpen}
      onClose={handleCloseModal}
    />
  );
};
