import { Flex } from "@chakra-ui/react";
import React from "react";
import { Props } from "./Feature.types";

export const Feature = ({ children, ...rest }: Props) => {
  return (
    <Flex
      backgroundColor="white"
      borderColor="gray.200"
      borderRadius="12"
      borderWidth="1px"
      flexDirection="column"
      p={5}
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
};
