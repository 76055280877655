import React, { useState } from "react";
import {
  Text,
  Flex,
  Box,
  Spacer,
  Switch,
  FormControl,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import { translations } from "../../i18n/translations";
import { t } from "i18next";
import { Logo } from "../../components/LogoBlack/LogoBlack";
import { PricingCard } from "./PricingCard/PricingCard";
import { PresentationProps } from "./PayWall.types";

export const PayWallPresentation = (props: PresentationProps) => {
  const { offerings, onLogout, onStartPurchase } = props;
  const screen = translations.screens.paywall;
  const [isMonthly, setIsMonthly] = useState(Boolean);

  const webOffers = offerings?.all["web"];
  const offeringMetadata = webOffers?.metadata as any;
  const packages = !isMonthly
    ? offeringMetadata?.webPaywall?.packages?.monthlyPackage
    : offeringMetadata?.webPaywall?.packages?.annualPackage;

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  return (
    <>
      <Flex gap="2" p={2}>
        <Box gap="2">
          <Logo />
        </Box>
        <Spacer></Spacer>
        {/* <Box gap="2">Welcome, Logout</Box> */}
        <Flex direction="row" gap={1}>
          <Text>Welcome, </Text>
          <Link
            _focus={{ boxShadow: "none" }}
            //as={RouterLink}
            style={{ textDecoration: "none" }}
            // to={Routing.Login}
            onClick={onLogout}
          >
            <Text color="#FB7C23" fontSize={14} fontWeight={600} mt="2px">
              Log Out
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Flex
        alignItems={["center", "center"]}
        flexDirection="column"
        justifyContent="center"
        pt={["50px", "50px", 0, 0]}
        px="20%"
      >
        <Flex alignItems={["center"]} flexDirection="column">
          <Box
            backgroundColor="#FFFAF7"
            borderRadius={999}
            height="24px"
            padding="2px"
            textAlign="center"
            width="73px"
          >
            <Text color="#FB7C23" fontSize="12px" fontWeight={700}>
              {t<string>(screen.title)}
            </Text>
          </Box>
          <Text
            fontSize={{ base: "24px", sm: "24px", md: "24px", lg: "48px" }}
            fontWeight={700}
            lineHeight={{ base: "24px", sm: "24px", md: "24px", lg: "48px" }}
            minWidth={{ base: "150%", sm: "150%", md: "150%", lg: "960px" }}
            textAlign="center"
          >
            {t<string>(screen.heading)}
          </Text>
          <Text
            color="gray.600"
            fontSize="20px"
            fontWeight={400}
            lineHeight="24px"
            marginTop="24px"
            minWidth={{ base: "150%", sm: "150%", md: "150%", lg: "100%" }}
            textAlign="center"
          >
            {t<string>(screen.description)}
          </Text>
        </Flex>
        <br />

        <Flex>
          <Flex align="center">
            <FormControl alignItems="center" display="flex">
              <FormLabel fontSize="16px" fontWeight="600" mb="0">
                <span style={{ color: !isMonthly ? "#FB7C23" : "#667085" }}>
                  Monthly
                </span>
              </FormLabel>
              <Flex alignItems="center">
                <Switch
                  colorScheme="gray"
                  isChecked={isMonthly}
                  size="lg"
                  sx={{
                    "span.chakra-switch__track:not([data-checked])": {
                      backgroundColor: "gray",
                    },
                  }}
                  onChange={handleToggle}
                />
                <FormLabel fontSize="16px" fontWeight="600" mb="0" ml="2">
                  <span style={{ color: isMonthly ? "#FB7C23" : "#667085" }}>
                    Annually
                  </span>
                </FormLabel>
              </Flex>
            </FormControl>
          </Flex>
        </Flex>

        <Flex
          direction={["column", "column", "column", "row"]}
          marginX={-2}
          marginY={10}
        >
          {packages &&
            packages.map((pkg: any) => (
              <Box key={pkg.planPackageId} marginX={4}>
                <PricingCard
                  packageData={pkg}
                  onStartPurchase={onStartPurchase}
                />
              </Box>
            ))}
        </Flex>

        <Flex alignItems="center" gap={1}>
          <Text color="gray.600" fontSize={20} fontWeight={700}>
            Have a promo code?
          </Text>
          <Text color="#F27712" fontSize={20} fontWeight={700}>
            Click here
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
