import { nosPurple } from "../colors/nosPurple";

export const CheckboxStyle = {
  baseStyle: {
    control: {
      // borderColor: orange[400],
      // backgroundColor: orange[400],
      _checked: {
        backgroundColor: nosPurple[700],
        borderColor: nosPurple[700],
        _hover: {
          backgroundColor: nosPurple[800],
          borderColor: nosPurple[700],
        },
      },
    },
    label: {
      color: "#172B4D",
      "font-size": "14px",
      "font-weight": "400",
    },
  },
};
