import { useCallback, useState } from "react";
import { DEFAULT_ITEMS_PER_PAGE } from "../constants/pagination";

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const isPrevDisabled = currentPage === 1 || !totalPages;
  const isNextDisabled = currentPage === totalPages || !totalPages;
  const onClickNext = useCallback(() => {
    setCurrentPage((prevState) => prevState + 1);
  }, [setCurrentPage]);
  const onClickPrev = useCallback(() => {
    setCurrentPage((prevState) => prevState - 1);
  }, [setCurrentPage]);
  const onChangeItemsPerPage = useCallback(
    (amount: number) => {
      setCurrentPage(1);
      setItemsPerPage(amount);
    },
    [setCurrentPage, setItemsPerPage]
  );

  return {
    currentPage,
    itemsPerPage,
    totalPages,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    onClickPrev,
    onClickNext,
    setTotalItems,
    setCurrentPage,
  };
};
