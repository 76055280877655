import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getJobsByStatus } from "./getJobsByStatus";
import { TJobs } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

type Props = {
  currentPage: number;
  itemsPerPage: number;
  contractorId?: number;
  companyId?: number;
  status: string;
};

export const useJobsByStatus = ({
  currentPage,
  itemsPerPage,
  companyId,
  status,
  contractorId,
}: Props) => {
  const toast = useToast();
  return useQuery<TJobs>(
    Queries.JobsByStatus(
      currentPage,
      itemsPerPage,
      status,
      companyId!,
      contractorId
    ),
    getJobsByStatus(
      currentPage,
      itemsPerPage,
      status,
      companyId!,
      contractorId
    ),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.jobs_error),
          status: "error",
          position: "top-right",
        });
      },
      enabled: Boolean(companyId || contractorId),
    }
  );
};
