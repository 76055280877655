import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getAvailableJobs } from "./getAvailableJobs";
import { TRequestProps } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { TJobs } from "../Jobs/types";

export const useAvailableJobs = ({
  currentPage,
  itemsPerPage,
  jobTypeId,
  miles,
  lat,
  lng,
  zip,
  isUserFetched,
}: TRequestProps) => {
  const toast = useToast();
  return useQuery<TJobs>(
    Queries.AvailableJobs(
      currentPage,
      itemsPerPage,
      jobTypeId,
      miles,
      lat,
      lng,
      zip
    ),
    getAvailableJobs({
      currentPage,
      itemsPerPage,
      jobTypeId,
      miles,
      lat,
      lng,
      zip,
    }),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.jobs_error),
          status: "error",
          position: "top-right",
        });
      },
      enabled: isUserFetched,
    }
  );
};
