import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { RemoveFromTeamSteps } from "./RemoveFromTeam.types";
import { TUser } from "../../api/modules/UserById/types";
import { translations } from "../../i18n/translations";

type Props = {
  isLoadingUser: boolean;
  isAccepted: boolean;
  isOpen: boolean;
  isRemoving: boolean;
  step: RemoveFromTeamSteps;
  userData?: TUser;
  onClose: () => void;
  onRemove: () => void;
  onChangeAccepted: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RemoveFromTeamPresentation = (props: Props) => {
  const {
    isAccepted,
    isLoadingUser,
    isOpen,
    isRemoving,
    step,
    userData,
    onClose,
    onRemove,
    onChangeAccepted,
  } = props;
  const screen = translations.screens.removeFromTeam;
  const { firstName, lastName } = userData || {};

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={7} px={10}>
            {step === RemoveFromTeamSteps.Details && (
              <Flex justifyContent="center">
                <Text fontSize="xl">{t<string>(screen.details.title)}</Text>
              </Flex>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={12}>
            {isLoadingUser ? (
              <Flex justifyContent="center" my={4}>
                <Spinner />
              </Flex>
            ) : (
              <>
                {step === RemoveFromTeamSteps.Details && (
                  <Flex flexDirection="column">
                    <Text color="gray.500" textAlign="center">
                      {t<string>(screen.details.description)}
                    </Text>
                    <br />
                    <Checkbox
                      isChecked={isAccepted}
                      name="roles"
                      onChange={onChangeAccepted}
                    >
                      {t<string>(screen.details.accept)}
                    </Checkbox>
                    <Flex justifyContent="center" mt={5}>
                      <Button
                        colorScheme="red"
                        isDisabled={isRemoving || !isAccepted}
                        isLoading={isRemoving}
                        size="sm"
                        onClick={onRemove}
                      >
                        {t<string>(screen.details.remove)}
                      </Button>
                    </Flex>
                  </Flex>
                )}
                {step === RemoveFromTeamSteps.Result && (
                  <Flex flexDirection="column">
                    <Text fontSize="xl" fontWeight={600} textAlign="center">
                      {t<string>(screen.resultScreen.title)}
                    </Text>
                    <br />
                    <Text>
                      {t<string>(screen.resultScreen.description, {
                        name: `${firstName || ""} ${lastName || ""}`,
                      })}
                    </Text>
                    <br />
                  </Flex>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
