import { Image, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { translations } from "../../../../i18n/translations";
import ResultImage from "../../assets/thankyou.png";

type Props = {
  firstName: string;
};

export const ResultStep = (props: Props) => {
  const { firstName } = props;
  const screen = translations.screens.inviteTeamMember.resultStep;
  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <Image alt="Invite Team Member" src={ResultImage} />
      <br />
      <Text fontSize="xl" fontWeight={600}>
        {t<string>(screen.thankYou)}
      </Text>
      <br />
      <Text textAlign="center">
        {t<string>(screen.thankYouDescription, { first_name: firstName })}
      </Text>
      <br />
    </Flex>
  );
};
