import { useNavigate } from "react-router-dom";
import { Routing } from "../constants/routing";
import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { translations } from "../i18n/translations";
import { t } from "i18next";
import { removeAccessToken } from "./removeAccessToken";

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useCallback(() => {
    const isConfirmed = window.confirm(
      t<string>(translations.common.confirmLogOut)
    );
    if (!isConfirmed) return;
    removeAccessToken();
    queryClient.clear();
    navigate(Routing.Login);
  }, [navigate, queryClient]);
};
