import { TJob } from "../../api/modules/Jobs/types";

export const getAddressCopy = (job: TJob) => {
  const {
    projectAddressZip,
    projectAddressStreet,
    projectAddressCity,
    projectAddressState,
  } = job;

  return `${projectAddressStreet || ""} ${projectAddressCity || ""} ${
    projectAddressState || ""
  } ${projectAddressZip || ""}`;
};
