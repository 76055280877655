import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { ResultScreen } from "./components/ResultScreen";
import { CreateManifestSteps } from "./CreateManifestModal.types";
import { translations } from "../../../../i18n/translations";
import { Details } from "./components/Details";

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onCreateManifest: (startDate: string, endDate: string) => void;
  step: CreateManifestSteps;
};

export const CreateManifestModalPresentation = (props: Props) => {
  const { isLoading, isOpen, onClose, onCreateManifest, step } = props;
  const screen = translations.screens.pumperPanel.manifests.createManifestModal;

  return (
    <>
      <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="800px">
          <ModalHeader mt={9} px={10}>
            <Flex alignItems="center" justifyContent="center">
              {step === CreateManifestSteps.Details && (
                <Flex flexDirection="column">
                  <Text fontSize="xl" fontWeight={700}>
                    {t<string>(screen.details.title)}
                  </Text>
                  <Text fontSize="medium">
                    {t<string>(screen.details.description)}
                  </Text>
                </Flex>
              )}
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mb={5} px={12}>
            {step === CreateManifestSteps.Details && (
              <Details
                isLoading={isLoading}
                onClose={onClose}
                onCreateManifest={onCreateManifest}
              />
            )}
            {step === CreateManifestSteps.Result && <ResultScreen />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
