import { TPropertyContact } from "../../../../api/modules/Jobs/types";
import { Flex } from "@chakra-ui/react";
import React from "react";
import { PersonCard } from "../../../../components/PersonCard";

type Props = {
  data: TPropertyContact[];
};

export const PropertyContacts = (props: Props) => {
  const { data } = props;
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      maxWidth={["100%", "100%", "100%", "450px"]}
      width="100%"
    >
      {data.map((item) => (
        <Flex key={item.emailAddress} mb={3}>
          <PersonCard
            email={item.emailAddress}
            firstName={item.firstName}
            lastName={item.lastName}
            phone={item.phoneNumber}
          />
        </Flex>
      ))}
    </Flex>
  );
};
