import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AssignJobSteps } from "./AssignJob.types";
import { ModalsContext } from "../../contexts/ModalsContext";
import { AssignJobPresentation } from "./AssignJob.presentation";
import { useStatics } from "../../api/modules/Statics";
import { useJob } from "../../api/modules/Job";
import { useTeam } from "../../api/modules/Team";
import { useAssignJob } from "../../api/modules/AssignJob";
import { useAcceptJob } from "../../api/modules/AcceptJob/useAcceptJob";

type Props = {
  jobId?: number;
  isReassignMode?: boolean;
  preselectedUserId?: number;
};

export const AssignJob = (props: Props) => {
  const { isReassignMode, preselectedUserId } = props;
  const { assignJob } = useContext(ModalsContext);
  const initialStep = isReassignMode
    ? AssignJobSteps.UserSelection
    : AssignJobSteps.Details;
  const [step, setStep] = useState<AssignJobSteps>(initialStep);
  const { mutateAsync: handleRequestAssignJob } = useAssignJob();
  const { mutateAsync: handleRequestAcceptJob } = useAcceptJob();
  const [currentUser, setCurrentUser] = useState<number | undefined>(
    preselectedUserId
  );
  useEffect(() => {
    setCurrentUser(preselectedUserId);
  }, [preselectedUserId, setCurrentUser]);

  const { data: staticsData } = useStatics();
  const { data: jobData, isLoading } = useJob(props.jobId);
  const { data: teamData } = useTeam();

  const handleCloseModal = useCallback(() => {
    assignJob.onClose();
    setStep(initialStep);
  }, [assignJob, initialStep]);

  const handleSetCurrentUser = useCallback(
    (userId: number) => () => {
      setCurrentUser(userId);
    },
    [setCurrentUser]
  );

  const handleAssignJob = useCallback(() => {
    if (!props.jobId || !currentUser) return;
    handleRequestAssignJob(
      {
        jobId: props.jobId,
        contractorId: currentUser,
      },
      {
        onSuccess: () => {
          setStep(AssignJobSteps.Result);
        },
      }
    );
  }, [handleRequestAssignJob, currentUser, setStep, props.jobId]);

  const handleAcceptJob = useCallback(() => {
    if (!props.jobId) return;
    handleRequestAcceptJob(
      { jobId: props.jobId },
      {
        onSuccess: () => {
          setStep(AssignJobSteps.UserSelection);
        },
      }
    );
  }, [handleRequestAcceptJob, setStep, props.jobId]);

  const currentTeamMemberData = useMemo(() => {
    return (teamData || []).find((item) => item.userId === currentUser);
  }, [currentUser, teamData]);

  return (
    <AssignJobPresentation
      currentTeamMemberData={currentTeamMemberData}
      currentUser={currentUser}
      isLoadingJob={isLoading}
      isOpen={assignJob.isOpen}
      isReassignMode={isReassignMode}
      job={jobData}
      statics={staticsData}
      step={step}
      team={teamData}
      onAccept={handleAcceptJob}
      onAssignJob={handleAssignJob}
      onClose={handleCloseModal}
      onSetCurrentUser={handleSetCurrentUser}
    />
  );
};
