import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TJobsRaw } from "../Jobs/types";

export const getJobs =
  (userId: number, currentPage: number, itemsPerPage: number) => async () => {
    const { data } = await client.get<TJobsRaw>(
      Endpoints.getJobsByUserId(userId, currentPage, itemsPerPage)
    );
    return data;
  };
