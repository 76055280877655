import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { InviteFormType } from "../../InviteTeamMemberModal.types";
import { UseFormRegister } from "react-hook-form";
import { translations } from "../../../../i18n/translations";
import { TStatics } from "../../../../api/modules/Statics/types";

type Props = {
  isLoading: boolean;
  errors: FieldErrors<InviteFormType>;
  register: UseFormRegister<InviteFormType>;
  rolesOptions?: TStatics["roles"];
  onSubmit: () => void;
};

export const InviteForm = (props: Props) => {
  const { isLoading, errors, register, rolesOptions, onSubmit } = props;
  const screen = translations.screens.inviteTeamMember.inviteForm;
  return (
    <form onSubmit={onSubmit}>
      <FormControl isInvalid={Boolean(errors.firstName?.message)}>
        <FormLabel fontSize="sm" htmlFor="firstName">
          {t<string>(screen.firstName)}
        </FormLabel>
        <Input
          id="firstName"
          {...register("firstName", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.firstName && errors.firstName.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <FormControl isInvalid={Boolean(errors.lastName?.message)}>
        <FormLabel fontSize="sm" htmlFor="lastName">
          {t<string>(screen.lastName)}
        </FormLabel>
        <Input
          id="lastName"
          {...register("lastName", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.lastName && errors.lastName.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <FormControl isInvalid={Boolean(errors.email?.message)}>
        <FormLabel fontSize="sm" htmlFor="name">
          {t<string>(screen.email)}
        </FormLabel>
        <Input
          id="email"
          {...register("email", {
            required: "This is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Please enter a valid email",
            },
          })}
        />
        <FormErrorMessage>
          {errors.email && errors.email.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <FormControl isInvalid={Boolean(errors.roles?.message)}>
        <FormLabel fontSize="sm" htmlFor="roles">
          {t<string>(screen.roles)}
        </FormLabel>
        {(rolesOptions || []).map(({ roleId, displayName }) => (
          <Box key={roleId}>
            <Checkbox value={roleId} {...register("roles")}>
              {displayName}
            </Checkbox>
          </Box>
        ))}
        <FormErrorMessage>
          {errors.roles && errors.roles.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <br />
      <Flex justifyContent="center">
        <Button
          colorScheme="blue"
          isLoading={isLoading}
          size="sm"
          type="submit"
        >
          {t<string>(screen.inviteContractor)}
        </Button>
      </Flex>
    </form>
  );
};
