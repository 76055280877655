import React from "react";
import LogoImg from "./assets/logo.svg";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { Box, Image } from "@chakra-ui/react";
import { Props } from "./LogoBlack.types";

export const Logo = (props: Props) => {
  return (
    <Box height={props.height} width={props.width}>
      <Image
        alt={t(translations.common.projectName)}
        height="100%"
        src={LogoImg}
        width="100%"
      />
    </Box>
  );
};
