import { CreateTruckModalPresentation } from "./CreateTruckModal.presentation";
import React, { useContext, useState } from "react";
import { ModalsContext } from "../../../../contexts/ModalsContext";
import { useForm } from "react-hook-form";
import { PostTruckSteps, TruckFormType } from "./CreateTruckModal.types";
import { useCompanyTruck } from "../../../../api/modules/PostCompanyTruck";
import { useToast } from "@chakra-ui/react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";

type Props = {
  companyTruck?: Partial<TruckFormType> | undefined;
};

export const CreateTruckModal = (props: Props) => {
  const { companyTruck } = props;
  const toast = useToast();
  const screen = translations.screens.pumperPanel.truck.createTruckModal;
  const { createTruckModal } = useContext(ModalsContext);
  const [step] = useState<PostTruckSteps>(PostTruckSteps.TruckForm);
  const { mutateAsync: mutateAsyncInvite, isLoading: isLoadingInviteRequest } =
    useCompanyTruck();
  const {
    handleSubmit: handleSubmitInvite,
    register: registerInvite,
    formState: { errors: errorsInvite },
  } = useForm<TruckFormType>();

  const onSubmitInvite = async (data: TruckFormType) => {
    await handleSubmitInvite(async () => {
      await mutateAsyncInvite(
        {
          assetName: data.nickName,
          licensePlate: data.licensePlate,
          make: data.make,
          model: data.model,
          year: data.year,
          pumpingLicenceNumber: data.pumpingLicense,
          truckId: companyTruck?.truckId,
        },
        {
          onSuccess: () => {
            toast({
              title: t<string>(screen.resultStep.success),
              description: t<string>(screen.resultStep.successMassage),
              position: "top-right",
              status: "success",
              isClosable: true,
              variant: "subtle",
              containerStyle: { color: "#067647" },
            });
            createTruckModal.onClose();
          },
        }
      );
    })();
  };

  return (
    <CreateTruckModalPresentation
      initialValues={companyTruck}
      inviteErrors={errorsInvite}
      inviteRegister={registerInvite}
      isLoadingInviteRequest={isLoadingInviteRequest}
      isOpen={createTruckModal.isOpen}
      step={step}
      onClose={createTruckModal.onClose}
      onInvite={onSubmitInvite}
    />
  );
};
