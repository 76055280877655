export type InviteFormType = {
  firstName: string;
  lastName: string;
  email: string;
  roles: (string | number)[];
};

export type AdditionalDataFormType = {
  zip: string;
  addressLine1: string;
  phoneNumber: string;
};

export enum InviteSteps {
  InviteForm = "invite-form",
  AdditionalData = "additional-data",
  Result = "result",
}
