import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getStatics } from "./getStatics";
import { TStatics } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

export const useStatics = () => {
  const toast = useToast();
  return useQuery<TStatics>(Queries.Statics, getStatics, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      toast({
        title: "Error",
        description: t<string>(translations.errors.statics_error),
        status: "error",
        position: "top-right",
      });
    },
  });
};
