import { Endpoints } from "../../../constants/endpoints";
import { TStripeLinkRaw } from "./types";
import client from "../../client";

export const getStripeLink = async (companyId: number) => {
  const { data } = await client.get<TStripeLinkRaw>(
    Endpoints.getStripeLink(companyId)
  );
  return data.url;
};
