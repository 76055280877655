import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TCancelJob, TPayload } from "./types";

export const postCancelJob = async ({ jobId, reason }: TPayload) => {
  const { data } = await client.post<{ reason: string }, TCancelJob>(
    Endpoints.postCancelJob(jobId),
    {
      reason: reason || "",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
