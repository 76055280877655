import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Divider,
  Text,
  Tag,
} from "@chakra-ui/react";
import { getAddressCopy } from "../../../../utils/Job/getAddressCopy";
import React, { useMemo } from "react";
import { TJob } from "../../../../api/modules/Jobs/types";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { getScheduleCopy } from "../../../../utils/Job/getScheduleCopy";
import { TStatics } from "../../../../api/modules/Statics/types";
import { getJobTypeCopy } from "../../../../utils/Job/getJobTypeCopy";
import { PersonPopover } from "../../../../components/PersonPopover";
import { getTagColor } from "../../../../utils/Job/getTagColor";

type Props = {
  job?: TJob;
  statics?: TStatics;
};

export const JobInfo = (props: Props) => {
  const { job, statics } = props;
  const screen = translations.screens.assignJob;
  const jobTypeCopies = getJobTypeCopy(job, statics);
  const lastPompOut = useMemo(
    () =>
      statics?.lastPumpoutOptions.find((item) => item.id === job?.lastPumpOutId)
        ?.description,
    [job, statics]
  );
  const reasons = useMemo(
    () =>
      job?.jobReasonIds.reduce((acc, reasonId) => {
        const currentReason = statics?.jobReasons.find(
          (reason) => reason.id === reasonId
        )?.description;
        if (currentReason) {
          acc += `${acc.length ? ", " : ""}${currentReason}`;
        }
        return acc;
      }, ""),
    [job, statics]
  );
  return (
    <Flex flexDirection="column">
      <TableContainer>
        <Table size="sm" variant="unstyled" whiteSpace="break-spaces">
          <Tbody fontSize="sm">
            <Tr>
              <Td color="gray.500">{t<string>(screen.details.table.status)}</Td>
              <Td>{job?.jobStatus}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.contact)}
              </Td>
              <Td>
                {(job?.propertyContacts || []).map((item) => (
                  <Flex key={item.emailAddress}>
                    <PersonPopover
                      email={item.emailAddress}
                      firstName={item.firstName}
                      lastName={item.lastName[0] || ""}
                      phone={item.phoneNumber}
                    />
                  </Flex>
                ))}
              </Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.schedule)}
              </Td>
              <Td>{job ? getScheduleCopy(job, statics) : "-"}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.jobLocation)}
              </Td>
              <Td>{job ? getAddressCopy(job) : "-"}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.jobType)}
              </Td>
              <Td>
                <Tag colorScheme={getTagColor(job?.jobTypeId || 0)}>
                  <Text size="12px">{jobTypeCopies.combined}</Text>
                </Tag>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <br />
      <Divider />
      <br />
      <Text fontSize="12px" fontWeight={700}>
        {t<string>(screen.details.propertySummary)}
      </Text>
      <TableContainer mb={4}>
        <Table mt={3} size="sm" variant="unstyled" whiteSpace="break-spaces">
          <Tbody fontSize="sm">
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.propertyType)}
              </Td>
              <Td>
                {job?.propertyUse
                  ? t<string>(screen.commercial)
                  : t<string>(screen.residential)}
              </Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.lastPumpOut)}
              </Td>
              <Td>{lastPompOut}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500">
                {t<string>(screen.details.table.reasons)}
              </Td>
              <Td>{reasons}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
