export const DISTANCE_OPTIONS = [
  {
    label: "10 miles",
    value: 10,
  },
  {
    label: "25 miles",
    value: 25,
  },
  {
    label: "50 miles",
    value: 50,
  },
  {
    label: "100 miles",
    value: 100,
  },
];

export const allJobTypesOption = {
  name: "All job types",
  id: undefined,
};
