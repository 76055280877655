import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { Props } from "./TradeLicenses.types";

export const TradeLicenses = (props: Props) => {
  const { isUpdatingProfile, onEditField, formData, isEditMode } = props;
  const [isInspectionsChecked, setIsInspectionsChecked] = useState(
    !!formData.plumbingLicense
  );
  const [isMaintenanceChecked, setIsMaintenanceChecked] = useState(
    !!formData.septicSystemInspectionsLicense
  );

  useEffect(() => {
    setIsInspectionsChecked(!!formData.plumbingLicense);
  }, [formData.plumbingLicense]);

  useEffect(() => {
    setIsMaintenanceChecked(!!formData.septicSystemInspectionsLicense);
  }, [formData.septicSystemInspectionsLicense]);

  const handleInspectionsCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsInspectionsChecked(isChecked);
    if (!isChecked) {
      const syntheticEvent = {
        target: {
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onEditField("plumbingLicense")(syntheticEvent);
    }
  };

  const handleMaintenanceCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setIsMaintenanceChecked(isChecked);
    if (!isChecked) {
      const syntheticEvent = {
        target: {
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onEditField("septicSystemInspectionsLicense")(syntheticEvent);
    }
  };

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="">
        <Tbody fontSize="sm">
          <Tr>
            <Td>
              <Flex flexDirection="column" gap="16px">
                <Flex flexDirection="column" gap={2}>
                  <Checkbox
                    isChecked={isInspectionsChecked}
                    isDisabled={isUpdatingProfile}
                    onChange={handleInspectionsCheckboxChange}
                  >
                    Inspections
                  </Checkbox>
                  <Input
                    disabled={
                      !isEditMode || isUpdatingProfile || !isInspectionsChecked
                    }
                    placeholder="Inspection license no."
                    value={formData.plumbingLicense}
                    width="229px"
                    onChange={onEditField("plumbingLicense")}
                  />
                </Flex>
                <Checkbox>Pump Outs</Checkbox>
                <Flex flexDirection="column" gap={2}>
                  <Checkbox
                    isChecked={isMaintenanceChecked}
                    isDisabled={isUpdatingProfile}
                    onChange={handleMaintenanceCheckboxChange}
                  >
                    Maintenance
                  </Checkbox>
                  <Input
                    disabled={
                      !isEditMode || isUpdatingProfile || !isMaintenanceChecked
                    }
                    placeholder="Maintenance license no."
                    value={formData.septicSystemInspectionsLicense}
                    width="229px"
                    onChange={onEditField("septicSystemInspectionsLicense")}
                  />
                </Flex>

                <Checkbox>Repair</Checkbox>
              </Flex>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
