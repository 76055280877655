import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { TruckFormType, PostTruckSteps } from "./CreateTruckModal.types";
import { UseFormRegister } from "react-hook-form";
import { TruckForm } from "./components/TruckForm";
import { ResultStep } from "./components/ResultStep";

type Props = {
  isOpen: boolean;
  isLoadingInviteRequest: boolean;
  onClose: () => void;
  onInvite: (data: TruckFormType) => void;
  step: PostTruckSteps;
  inviteErrors: FieldErrors<TruckFormType>;
  inviteRegister: UseFormRegister<TruckFormType>;
  initialValues?: Partial<TruckFormType>;
};

export const CreateTruckModalPresentation = (props: Props) => {
  const {
    isOpen,
    isLoadingInviteRequest,
    initialValues,
    inviteRegister,
    inviteErrors,
    step,
    onClose,
    onInvite,
  } = props;
  const screen = translations.screens.pumperPanel.truck.createTruckModal;
  return (
    <>
      <Modal isOpen={isOpen} size="xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb={0} mt={3} pb={-10} px={12} textAlign="left">
            {step !== PostTruckSteps.Result && (
              <Text fontSize="lg">
                {t<string>(
                  screen[PostTruckSteps.TruckForm ? "title" : "inviteSent"]
                )}
              </Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5} px={12}>
            {step === PostTruckSteps.Result ? (
              <ResultStep />
            ) : (
              <>
                {step === PostTruckSteps.TruckForm && (
                  <>
                    <Box mb={5} textAlign="left">
                      <Text fontSize="sm">{t<string>(screen.description)}</Text>
                    </Box>
                    <TruckForm
                      errors={inviteErrors}
                      initialValues={initialValues}
                      isLoading={isLoadingInviteRequest}
                      register={inviteRegister}
                      onClose={onClose}
                      onSubmit={onInvite}
                    />
                  </>
                )}
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
