import React from "react";
import LogoImg from "./assets/img.png";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { Props } from "./Logo.types";

export const Logo = (props: Props) => {
  return (
    <img alt={t(translations.common.projectName)} src={LogoImg} {...props} />
  );
};
