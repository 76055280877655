import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TAcceptInvitation, TAcceptInvitationBody } from "./types";

export const postAcceptInvitation = async (payload: TAcceptInvitationBody) => {
  const { data } = await client.post<TAcceptInvitationBody, TAcceptInvitation>(
    Endpoints.postAcceptInvitation,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
