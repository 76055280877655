import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { BsTrash as TrashIcon } from "react-icons/bs";
import { FiPlus as PlusIcon } from "react-icons/fi";
import { TfiLocationPin as PinIcon } from "react-icons/tfi";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./WorkLocations.types";

export const WorkLocations = (props: Props) => {
  const {
    isEditMode,
    isEditable,
    formData,
    companyData,
    isLoading,
    onCancelEditMode,
    onSave,
    onSetEditMode,
    onEditLocationField,
    onRemoveCompanyLocation,
    onAddCompanyLocation,
  } = props;
  const screen = translations.screens.profile;

  return (
    <>
      <br />
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="lg" fontWeight={600}>
            {t<string>(screen.workLocations)}
          </Text>
          {isEditMode && (
            <Flex ml={3}>
              <Button
                isLoading={isLoading}
                leftIcon={<PlusIcon />}
                size="xs"
                variant="outline"
                onClick={onAddCompanyLocation}
              >
                {t<string>(screen.addLocation)}
              </Button>
            </Flex>
          )}
        </Flex>

        <Flex justifyContent="flex-end">
          {isEditMode && (
            <>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                size="sm"
                variant="outline"
                onClick={onCancelEditMode}
              >
                {t<string>(screen.cancel)}
              </Button>
              <Box ml={3}>
                <Button
                  colorScheme="blue"
                  isLoading={isLoading}
                  size="sm"
                  onClick={onSave}
                >
                  {t<string>(screen.save)}
                </Button>
              </Box>
            </>
          )}
          {!isEditMode && isEditable && (
            <Button colorScheme="blue" size="sm" onClick={onSetEditMode}>
              {t<string>(screen.edit)}
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt={8}>
        {isEditMode ? (
          <Flex flexDirection="column">
            {(formData.companyLocations || []).map((location, index) => (
              <FormControl key={index} maxWidth="550px">
                <Flex alignItems="center" mb={3}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <PinIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      borderRightRadius="0px"
                      placeholder={t<string>(screen.enterZipCode)}
                      type="number"
                      value={location.zipcode || ""}
                      onChange={onEditLocationField(index, "zipcode")}
                    />
                  </InputGroup>
                  <InputGroup>
                    <Input
                      borderLeftRadius="0px"
                      placeholder={t<string>(screen.radius)}
                      type="number"
                      value={location.radius || ""}
                      onChange={onEditLocationField(index, "radius")}
                    />
                    <InputRightAddon>{t<string>(screen.miles)}</InputRightAddon>
                  </InputGroup>
                  <Flex ml={3}>
                    <TrashIcon
                      color="brown"
                      cursor="pointer"
                      size="16px"
                      onClick={onRemoveCompanyLocation(index)}
                    />
                  </Flex>
                </Flex>
              </FormControl>
            ))}
          </Flex>
        ) : (
          <Flex flexDirection="column">
            {(companyData?.companyLocations || []).map((location) => (
              <Flex
                key={location.companyLocationId}
                borderBottom="1px solid"
                borderColor="gray.200"
                fontSize="sm"
                fontWeight={500}
                pl={0}
                pr={6}
                py={4}
              >
                <Text>
                  {t<string>(screen.zipCode)} {location.zipcode}
                </Text>
                {location.radius && (
                  <Flex ml={2}>
                    <Text>
                      + {location.radius} {t<string>(screen.miles)}
                    </Text>
                  </Flex>
                )}
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </>
  );
};
