import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { TruckFormType } from "../../CreateTruckModal.types";
import { useForm, UseFormRegister } from "react-hook-form";
import { translations } from "../../../../../../i18n/translations";

type Props = {
  isLoading: boolean;
  errors: FieldErrors<TruckFormType>;
  register: UseFormRegister<TruckFormType>;
  onSubmit: (data: TruckFormType) => void;
  onClose: () => void;
  initialValues?: Partial<TruckFormType>;
};

export const TruckForm = (props: Props) => {
  const { isLoading, errors, onSubmit, initialValues, onClose } = props;

  const screen =
    translations.screens.pumperPanel.truck.createTruckModal.truckForm;

  const { register, handleSubmit } = useForm<TruckFormType>({
    defaultValues: initialValues,
  });

  const onSubmitForm = (data: TruckFormType) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid gap={4} templateColumns="repeat(2, 1fr)">
        <GridItem colSpan={2}>
          <FormControl isInvalid={Boolean(errors.nickName?.message)}>
            <FormLabel fontSize="small" htmlFor="nickName">
              {t<string>(screen.nickName)}
            </FormLabel>
            <Input
              id="nickName"
              {...register("nickName", {
                required: "This is required",
              })}
              placeholder={t<string>(screen.placeHolder.nickName)}
            />
            <FormErrorMessage>
              {errors.nickName && errors.nickName.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.licensePlate?.message)}>
            <FormLabel fontSize="small" htmlFor="licensePlate">
              {t<string>(screen.licensePlate)}
            </FormLabel>
            <Input
              id="licensePlate"
              {...register("licensePlate", {
                required: "This is required",
              })}
            />
            <FormErrorMessage>
              {errors.licensePlate && errors.licensePlate.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.make?.message)}>
            <FormLabel fontSize="small" htmlFor="make">
              {t<string>(screen.make)}
            </FormLabel>
            <Input
              id="make"
              {...register("make", {
                required: "This is required",
              })}
            />
            <FormErrorMessage>
              {errors.make && errors.make.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.model?.message)}>
            <FormLabel fontSize="small" htmlFor="model">
              {t<string>(screen.model)}
            </FormLabel>
            <Input
              id="model"
              {...register("model", {
                required: "This is required",
              })}
            />
            <FormErrorMessage>
              {errors.model && errors.model.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={Boolean(errors.year?.message)}>
            <FormLabel fontSize="small" htmlFor="year">
              {t<string>(screen.year)}
            </FormLabel>
            <Input
              id="year"
              {...register("year", {
                required: "This is required",
              })}
            />
            <FormErrorMessage>
              {errors.year && errors.year.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl isInvalid={Boolean(errors.pumpingLicense?.message)}>
            <FormLabel fontSize="small" htmlFor="pumpingLicense">
              {t<string>(screen.pumpingLicense)}
            </FormLabel>
            <Input
              id="pumpingLicense"
              {...register("pumpingLicense", {
                required: "This is required",
              })}
            />
            <FormLabel
              fontSize="small"
              fontWeight={400}
              htmlFor="pumpingLicense"
              mt={1}
            >
              {t<string>(screen.placeHolder.pumpingLicense)}
            </FormLabel>
            <FormErrorMessage>
              {errors.pumpingLicense && errors.pumpingLicense.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Flex gap={2} justifyContent="right" mt={4}>
        <Button
          colorScheme="gray"
          size="sm"
          variant="outline"
          onClick={onClose}
        >
          {t<string>(screen.cancel)}
        </Button>
        <Button
          colorScheme="blue"
          isLoading={isLoading}
          size="sm"
          type="submit"
        >
          {t<string>(screen.add)}
        </Button>
      </Flex>
    </form>
  );
};
