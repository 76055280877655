import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { AdditionalDataFormType } from "../../InviteTeamMemberModal.types";
import { UseFormRegister } from "react-hook-form";
import { translations } from "../../../../i18n/translations";

type Props = {
  isLoading: boolean;
  errors: FieldErrors<AdditionalDataFormType>;
  register: UseFormRegister<AdditionalDataFormType>;
  onSubmit: () => void;
};

export const AdditionalDataForm = (props: Props) => {
  const { isLoading, errors, register, onSubmit } = props;
  const screen = translations.screens.inviteTeamMember.formAdditional;
  return (
    <form onSubmit={onSubmit}>
      <FormControl isInvalid={Boolean(errors.zip?.message)}>
        <FormLabel fontSize="sm" htmlFor="zip">
          {t<string>(screen.workLocation)}
        </FormLabel>
        <Input
          id="zip"
          placeholder={t<string>(screen.workLocationPlaceholder)}
          {...register("zip", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>{errors.zip && errors.zip.message}</FormErrorMessage>
      </FormControl>
      <br />
      <FormControl isInvalid={Boolean(errors.addressLine1?.message)}>
        <FormLabel fontSize="sm" htmlFor="addressLine1">
          {t<string>(screen.address)}
        </FormLabel>
        <Input
          id="addressLine1"
          placeholder={t<string>(screen.addressPlaceholder)}
          {...register("addressLine1", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.addressLine1 && errors.addressLine1.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <FormControl isInvalid={Boolean(errors.phoneNumber?.message)}>
        <FormLabel fontSize="sm" htmlFor="phoneNumber">
          {t<string>(screen.phone)}
        </FormLabel>
        <Input
          id="phoneNumber"
          placeholder={t<string>(screen.phonePlaceholder)}
          {...register("phoneNumber", {
            required: "This is required",
          })}
        />
        <FormErrorMessage>
          {errors.phoneNumber && errors.phoneNumber.message}
        </FormErrorMessage>
      </FormControl>
      <br />
      <br />
      <Flex justifyContent="center">
        <Button
          colorScheme="blue"
          isLoading={isLoading}
          size="sm"
          type="submit"
        >
          {t<string>(screen.addDetails)}
        </Button>
      </Flex>
    </form>
  );
};
