import { Button, Flex, FlexProps, Icon, Select, Text } from "@chakra-ui/react";
import { translations } from "../../i18n/translations";
import { t } from "i18next";
import React, { SyntheticEvent } from "react";
import { ITEMS_PER_PAGE_OPTIONS } from "./constants";
import {
  BiChevronLeft as ChevronLeft,
  BiChevronRight as ChevronRight,
} from "react-icons/bi";

type Props = {
  itemsPerPage: number;
  currentPage: number;
  totalPages: number;
  onClickNext: () => void;
  onClickPrev: () => void;
  onChangeItemsPerPage: (amount: number) => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
} & FlexProps;

export const Pagination = (props: Props) => {
  const {
    itemsPerPage,
    currentPage,
    totalPages,
    onClickNext,
    onClickPrev,
    isNextDisabled,
    isPrevDisabled,
    onChangeItemsPerPage,
    ...flexProps
  } = props;
  const screen = translations.common.pagination;
  const handleChangeItemsPerPage = (
    e: SyntheticEvent<HTMLSelectElement, Event>
  ) => {
    onChangeItemsPerPage(+e.currentTarget.value);
  };

  return (
    <Flex
      alignItems="center"
      flexDirection={["column", "column", "column", "row"]}
      justifyContent="space-between"
      {...flexProps}
    >
      <Flex alignItems="center" color="gray.600" mb={3}>
        <Text fontSize="sm" fontWeight={400} minWidth="150px">
          {t<string>(screen.rowsPerPage)}
        </Text>
        <Select
          borderRadius="md"
          size="sm"
          value={itemsPerPage}
          onChange={handleChangeItemsPerPage}
        >
          {ITEMS_PER_PAGE_OPTIONS.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center">
        {!!totalPages && (
          <Text color="gray.600" fontSize="sm" fontWeight={400}>
            {`${currentPage} of ${totalPages}`}
          </Text>
        )}
        <Flex ml={3}>
          <Button
            color="gray.700"
            colorScheme="ghost"
            isDisabled={isPrevDisabled}
            size="sm"
            onClick={onClickPrev}
          >
            <Icon as={ChevronLeft} fontSize="xl" />
          </Button>
        </Flex>
        <Flex ml={1}>
          <Button
            color="gray.700"
            colorScheme="ghost"
            isDisabled={isNextDisabled}
            size="sm"
            onClick={onClickNext}
          >
            <Icon as={ChevronRight} fontSize="xl" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
