import React, { useMemo } from "react";
import { Props } from "./AccessLevelGuard.types";

import { useUser } from "../../api/modules/User";

export const AccessLevelGuard = ({ children, permittedRoles }: Props) => {
  const { data } = useUser();
  const isPermitted = useMemo(
    () =>
      !permittedRoles ||
      data?.roles.some((role) => permittedRoles.includes(role)),
    [data, permittedRoles]
  );
  if (!isPermitted) return null;
  return <>{children}</>;
};
