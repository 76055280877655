import { Endpoints } from "../../../constants/endpoints";
import { TCompanyRaw } from "./types";
import client from "../../client";

export const getCompany = (companyId?: number | null) => async () => {
  const { data } = await client.get<TCompanyRaw>(
    Endpoints.getCompany(companyId!)
  );
  return data;
};
