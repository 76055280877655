import React from "react";

import { Box, BoxProps } from "@chakra-ui/react";
import { getTagColor } from "../../../../../utils/Job/getTagColor";

export const Marker = (
  props: BoxProps & { lat: number; lng: number; jobTypeId: number }
) => {
  const { lat, lng, jobTypeId } = props;

  return (
    <Box
      _hover={{
        outline: "8px solid rgba(0,0,0,0.2)",
      }}
      backgroundColor={getTagColor(jobTypeId)}
      borderColor="white"
      borderRadius="50%"
      cursor="pointer"
      height="20px"
      // @ts-ignore
      lat={lat}
      lng={lng}
      opacity="0.7"
      outline="8px solid rgba(0,0,0,0.1)"
      width="20px"
    />
  );
};
