import { Endpoints } from "../../../constants/endpoints";
import { TRequestProps } from "./types";
import client from "../../client";
import { TJobsRaw } from "../Jobs/types";

export const getAvailableJobs =
  (props: Omit<TRequestProps, "isUserFetched">) => async () => {
    const { data } = await client.get<TJobsRaw>(
      Endpoints.getAvailableJobs(props)
    );
    return data;
  };
