import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TRegisterUser, TAppleSignInBody } from "./types";

export const postAppleSignUp = async (payload: TAppleSignInBody) => {
  const { data } = await client.post<TAppleSignInBody, TRegisterUser>(
    Endpoints.postAppleSignUp,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
