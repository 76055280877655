import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUpdateUserBodyById } from "./types";

export const putUpdateUser = async ({
  userId,
  ...payload
}: Partial<TUpdateUserBodyById>) => {
  const data = await client.put<Partial<TUpdateUserBodyById>, TData>(
    Endpoints.putUpdateUserById(userId!),
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data.userData;
};
