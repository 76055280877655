import { useMutation } from "react-query";
import { Queries } from "../../../constants/queries";
import { getStripeLink } from "./getStripeLink";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { useUser } from "../User";

export const useGetStripeLink = () => {
  const toast = useToast();
  const { data: userData } = useUser();
  return useMutation<string>(
    Queries.GetStripeLink(userData?.company?.companyId ?? 0),
    () => getStripeLink(userData?.company?.companyId ?? 0),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.stripe_error),
          status: "error",
          position: "top-right",
        });
      },
    }
  );
};
