import { Flex, Icon, Link } from "@chakra-ui/react";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Props } from "./MenuItem.types";

export const MenuItem = ({ icon, children, link, py = 3, ...rest }: Props) => {
  return (
    <Link
      _activeLink={{
        bg: "gray.900",
        color: "gray.100",
      }}
      _focus={{ boxShadow: "none" }}
      _hover={{
        bg: "gray.900",
        color: "gray.100",
      }}
      as={RouterLink}
      borderRadius="lg"
      color="gray.500"
      cursor="pointer"
      fontSize={14}
      fontWeight={500}
      mx="4"
      px="4"
      py={py}
      style={{ textDecoration: "none" }}
      to={link}
      width="fill-available"
    >
      <Flex align="center" role="group" {...rest}>
        {icon && <Icon as={icon} fontSize="16" mr="3" />}
        {children}
      </Flex>
    </Link>
  );
};
