import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TPostTruck, TPostTruckBody } from "./types";

export const postCompanyTruck = async (
  payload: TPostTruckBody,
  companyId: number
) => {
  const { data } = await client.post<TPostTruckBody, TPostTruck>(
    Endpoints.postCompanyTruck(companyId),
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
