import React from "react";
import { MenuElement, MenuItemType } from "../../constants/menu";
import { Props } from "./MenuItems.types";
import MenuItem from "../MenuItem";
import { t } from "i18next";
import { AccessLevelGuard } from "../AccessLevelGuard";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Flex,
  Icon,
  useToken,
  FlexProps,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

const SingleMenuItem = ({
  item,
  ...rest
}: FlexProps & { item: MenuItemType }) => {
  return (
    <AccessLevelGuard key={item.title} permittedRoles={item.accessLevel}>
      <MenuItem key={item.title} icon={item.icon} link={item.link} {...rest}>
        {t<string>(item.title)}
      </MenuItem>
    </AccessLevelGuard>
  );
};
export const MenuItems = ({ items }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [gray100, gray900] = useToken("colors", ["gray.100", "gray.900"]);

  return (
    <>
      {items.map((item) => {
        if (item.type === MenuElement.SECTION) {
          const isActive = item.items.some((i) =>
            location.pathname.includes(i.link)
          );
          const onClickButton = () => {
            if (isActive) return;
            navigate(item.items[0].link);
          };

          return (
            <AccessLevelGuard
              key={item.title}
              permittedRoles={item.accessLevel}
            >
              <Accordion
                allowToggle
                borderRadius="lg"
                defaultIndex={isActive ? 0 : undefined}
                style={{
                  border: "0",
                  margin: "0 16px",
                  background: isActive ? gray900 : "transparent",
                }}
              >
                <AccordionItem>
                  <AccordionButton
                    _hover={{
                      background: gray900,
                      color: gray100,
                    }}
                    borderRadius="lg"
                    color="gray.500"
                    onClick={onClickButton}
                  >
                    <Flex alignItems="center" flex="1">
                      <Flex
                        align="center"
                        as="span"
                        flex="1"
                        style={{
                          fontSize: "14px",
                          padding: "3px 0",
                          fontWeight: 500,
                        }}
                      >
                        {item.icon && (
                          <Icon
                            _groupHover={{
                              color: "gray.500",
                            }}
                            as={item.icon}
                            fontSize="16"
                            mr="3"
                          />
                        )}
                        {t<string>(item.title)}
                      </Flex>
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel
                    pb={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px",
                      textAlign: "left",
                    }}
                  >
                    {item.items.map((i) => (
                      <SingleMenuItem
                        key={i.title}
                        item={i}
                        pb={4}
                        pt={1}
                        py={0}
                      />
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </AccessLevelGuard>
          );
        }
        return <SingleMenuItem key={item.title} item={item} />;
      })}
    </>
  );
};
