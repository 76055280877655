import React from "react";
import Layout from "../../../components/Layout";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Routing } from "../../../constants/routing";
import { translations } from "../../../i18n/translations";
import { PresentationProps } from "./Dumpout.types";
import { Link as RouterLink } from "react-router-dom";
import FullPageContent from "../../../components/FullPageContent";
import { format } from "date-fns";
import { MANIFEST_DATE_FORMAT } from "../../../constants/global";

export const DumpoutPresentation = (props: PresentationProps) => {
  const screen = translations.screens.pumperPanel.dumpout;
  const { pageTitleRef, breadcrumbsRef, isLoading, list } = props;

  const truncateAddress = (address: string) => {
    const maxLength = 40;
    if (address.length <= maxLength) {
      return address;
    }
    return address.substring(0, maxLength) + "...";
  };

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.page)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}

          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textAlign="left">
                      {t<string>(screen.table.recentDumpouts)}
                    </Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.contractor)}
                    </Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.pumpingLicenseNumber)}
                    </Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.volumeDumpedInGallons)}
                    </Th>
                    <Th textAlign="left">
                      {t<string>(screen.table.completedDate)}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(list?.dumpoutListItems || []).map((item) => (
                    <Tr
                      key={item.createdDate}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Td textAlign="left">
                        <Box fontWeight={500}>{item.facilityName}</Box>
                        <Box>
                          {item.address && (
                            <Tooltip
                              aria-label="Full address"
                              label={item.address}
                            >
                              <span>{truncateAddress(item.address)}</span>
                            </Tooltip>
                          )}
                        </Box>
                      </Td>
                      <Td textAlign="left">{item.contractorName}</Td>
                      <Td textAlign="left">{item.truckName}</Td>
                      <Td textAlign="left">{item.volumeDumpedInGallons}</Td>
                      <Td textAlign="left">
                        {item.completedDate &&
                          format(item.completedDate, MANIFEST_DATE_FORMAT)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(list?.dumpoutListItems || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noDumpout)}</Text>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
    </>
  );
};
