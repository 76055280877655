import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TData, TUpdateUserBody } from "./types";

export const putUpdateUser = async (payload: TUpdateUserBody) => {
  const data = await client.put<TUpdateUserBody, TData>(
    Endpoints.putUpdateUser,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data.userData;
};
