import { Avatar, Flex, FlexProps, Text } from "@chakra-ui/react";
import React from "react";
import { getProfileImageUrl } from "../../utils/getProfileImageUrl";

type Props = {
  firstName: string;
  lastName: string;
  email?: string | null;
  profilePicUrl?: string | null;
} & FlexProps;

export const PersonName = (props: Props) => {
  const { firstName, lastName, email, profilePicUrl, ...flexProps } = props;
  return (
    <Flex alignItems="center" {...flexProps}>
      <Avatar size="xs" src={getProfileImageUrl({ email, profilePicUrl })} />
      <Flex ml={2}>
        <Text fontSize="sm" fontWeight={500}>
          {`${firstName || ""} ${lastName || ""}`}
        </Text>
      </Flex>
    </Flex>
  );
};
