import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TCreateManifest, TPayload } from "./types";

export const postCreateManifest = async ({ startDate, endDate }: TPayload) => {
  const { data } = await client.post<
    { startDate: string; endDate: string },
    TCreateManifest
  >(
    Endpoints.postDumpoutLog(),
    {
      startDate,
      endDate,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
