import { Button, Flex, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { t } from "i18next";
import { translations } from "../../../../../../i18n/translations";
import {
  Month_Names_Short,
  OnDateSelected,
  RangeCalendarPanel,
  Weekday_Names_Short,
} from "chakra-dayzed-datepicker";
import { format } from "date-fns";
import {
  ISO_DATE_FORMAT,
  MANIFEST_DATE_FORMAT,
} from "../../../../../../constants/global";

type Props = {
  isLoading: boolean;
  onCreateManifest: (startDate: string, endDate: string) => void;
  onClose: () => void;
};

export const Details = (props: Props) => {
  const { isLoading, onCreateManifest, onClose } = props;
  const screen = translations.screens.pumperPanel.manifests.createManifestModal;

  const today = new Date();
  const [selectedDates, setSelectedDates] = useState<Date[]>([today, today]);

  const handleOnDateSelected: OnDateSelected = ({ date }) => {
    const newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        const firstTime = selectedDates[0];
        if (firstTime < date) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
        }
        setSelectedDates(newDates);
        return;
      }

      if (newDates.length === 2) {
        setSelectedDates([date]);
        return;
      }
    } else {
      newDates.push(date);
      setSelectedDates(newDates);
    }
  };

  const handleCreateManifestClick = () => {
    const startDate = format(selectedDates[0], ISO_DATE_FORMAT);
    const endDate = format(selectedDates[1], ISO_DATE_FORMAT);
    onCreateManifest(startDate, endDate);
  };

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column" mt={-2}>
        <RangeCalendarPanel
          configs={{
            dateFormat: "MM/dd/yyyy",
            monthNames: Month_Names_Short,
            dayNames: Weekday_Names_Short,
            firstDayOfWeek: 1,
          }}
          dayzedHookProps={{
            showOutsideDays: false,
            onDateSelected: handleOnDateSelected,
            selected: selectedDates,
            monthsToDisplay: 2,
          }}
          propsConfigs={{
            // dateNavBtnProps: {
            //   colorScheme: "blue",
            //   variant: "outline",
            // },
            dayOfMonthBtnProps: {
              defaultBtnProps: {
                // background: "#FB7C23",
                // color: "white",
                borderRadius: "full",
                _hover: {
                  background: "#FB7C23",
                  color: "white",
                  borderRadius: "full",
                },
              },
              isInRangeBtnProps: {
                backgroundColor: "#FB7C23",
                color: "white",
                borderRadius: "full",
                // borderColor: "blue.300",
              },
              selectedBtnProps: {
                background: "#FB7C23",
                // borderColor: "blue.300",
                color: "white",
                borderRadius: "full",
              },
              // todayBtnProps: {
              //   background: "teal.200",
              //   color: "teal.700",
              // },
            },
            // inputProps: {
            //   size: "sm",
            // },
          }}
          selected={selectedDates}
        />
      </Flex>
      <Flex flexDirection={["column", "column", "row"]} mt={4}>
        <Flex alignItems="left" gap={4}>
          <Input
            value={format(selectedDates[0], MANIFEST_DATE_FORMAT)}
            width="136px"
          />
          <Text fontWeight={600} mt={2}>
            -
          </Text>
          <Input
            readOnly={true}
            value={
              selectedDates.length > 1
                ? format(selectedDates[1], MANIFEST_DATE_FORMAT)
                : format(new Date(), MANIFEST_DATE_FORMAT)
            }
            width="136px"
          />
        </Flex>
        <Flex
          flex="1"
          gap={2}
          justifyContent={["flex-start", "flex-start", "flex-end"]}
          mt={["12px", "12px", "0px"]}
        >
          <Button variant="outline" onClick={onClose}>
            {t<string>(screen.details.cancel)}
          </Button>
          <Button
            _hover={{ bg: "orange.600" }}
            backgroundColor="#FB7C23"
            color="white"
            isLoading={isLoading}
            size="md"
            onClick={handleCreateManifestClick}
          >
            {t<string>(screen.details.createLog)}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
