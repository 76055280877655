import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Routing } from "./constants/routing";
// import { Main } from "./containers/Main";
import { Login } from "./containers/Login";
import { PortalsContextWrapper } from "./contexts/PortalsContext";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import { AvailableJobs } from "./containers/Jobs/AvailableJobs";
import { Jobs } from "./containers/Jobs/Jobs";
import { Job } from "./containers/Job";
import { Profile } from "./containers/Users/Profile";
import { Team } from "./containers/Team";
import { AcceptInvitation } from "./containers/AcceptInvitation";
import { ModalsContextWrapper } from "./contexts/ModalsContext";
import { User } from "./containers/Users/User";
import { ReactQueryDevtools } from "react-query/devtools";
import { LoginInformation } from "./containers/Settings/LoginInformation";
import { SettingsWrapper } from "./containers/Settings/SettingsWrapper";
import { theme } from "./constants/theme";
import { ForgotPassword } from "./containers/ForgotPassword";
import { PayWall } from "./containers/PayWall";
import { getRevenueCatKey } from "./utils/getRevenueCatKey";
import { LogLevel, Purchases } from "@revenuecat/purchases-js";
import { Signup } from "./containers/Signup";
import { getUserRevenueCatId } from "./utils/User/getUserRevenueCatId";
import { Manifests } from "./containers/PumperPanel/Manifests";
import { Dumpout } from "./containers/PumperPanel/Dumpout";
import { Truck } from "./containers/PumperPanel/Truck";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const revenueCatKey = getRevenueCatKey();
const revenueCatUserId = (getUserRevenueCatId() as string) || "tempUserId";
const initialUserId = revenueCatUserId;
Purchases.setLogLevel(LogLevel.Verbose);
const purchases = Purchases.configure(revenueCatKey, initialUserId);

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ChakraProvider theme={theme}>
        <ModalsContextWrapper>
          <PortalsContextWrapper>
            <BrowserRouter>
              <Routes>
                <Route element={<Login />} path={Routing.Login} />
                <Route
                  element={<PayWall purchases={purchases} />}
                  path={Routing.PayWall}
                />
                <Route element={<Signup />} path={Routing.Signup} />
                <Route
                  element={<ForgotPassword />}
                  path={Routing.ForgotPassword}
                />
                <Route
                  element={<AcceptInvitation />}
                  path={Routing.AcceptInvitation}
                />
                <Route element={<ProtectedRoutes />} path="/">
                  <Route
                    element={<Navigate to={Routing.Jobs} />}
                    path={Routing.Root}
                  />
                  <Route element={<Jobs />} path={Routing.Jobs} />
                  <Route
                    element={<AvailableJobs />}
                    path={Routing.AvailableJobs}
                  />
                  <Route element={<Job />} path={Routing.Job} />
                  <Route element={<Team />} path={Routing.Team} />
                  <Route element={<Profile />} path={Routing.Profile} />
                  <Route element={<User />} path={Routing.User} />
                  <Route
                    element={<SettingsWrapper />}
                    path={Routing.Settings.Root}
                  >
                    <Route
                      element={<LoginInformation />}
                      path={Routing.Settings.LoginInformation.Relative}
                    />
                  </Route>
                  <Route element={<Manifests />} path={Routing.Manifests} />
                  <Route element={<Dumpout />} path={Routing.Dumpout} />
                  <Route element={<Truck />} path={Routing.Truck} />
                </Route>
              </Routes>
            </BrowserRouter>
          </PortalsContextWrapper>
        </ModalsContextWrapper>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
