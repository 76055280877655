import React from "react";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Portal,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";

import { PresentationProps } from "./LoginInformation.types";
import { Link as RouterLink } from "react-router-dom";
import { translations } from "../../../i18n/translations";
import { Routing } from "../../../constants/routing";
import PasswordValidator from "../../../components/PasswordValidator";
import { AuthProviders } from "../../../api/modules/User/types";

export const LoginInformationPresentation = (props: PresentationProps) => {
  const screen = translations.screens.settings;
  const {
    currentPassword,
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    touchedFields,
    isChangePasswordDisabled,
    validateConfirmPassword,
    validatePassword,
    onSubmit,
    register,
    errors,
    user,
  } = props;

  return (
    <Flex flexDirection="column">
      <Portal containerRef={pageTitleRef}>
        {t<string>(screen.loginInformation.pageTitle)}
      </Portal>
      <Portal containerRef={breadcrumbsRef}>
        <Breadcrumb fontWeight={500}>
          <BreadcrumbItem color="gray.400">
            <BreadcrumbLink as={RouterLink} to={Routing.Root}>
              {t<string>(screen.loginInformation.breadcrumbs.root)}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>{t<string>(screen.loginInformation.breadcrumbs.settings)}</div>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div>
              {t<string>(screen.loginInformation.breadcrumbs.loginInformation)}
            </div>
          </BreadcrumbItem>
        </Breadcrumb>
      </Portal>
      <Flex flexDirection="column">
        <Text fontSize="lg" fontWeight={700}>
          {t<string>(screen.loginInformation.pageTitle)}
        </Text>
        <Box mt={2}>
          <Text color="gray.600" fontSize="sm">
            {user?.authProvider === AuthProviders.Email
              ? t<string>(screen.loginInformation.descriptionEmail)
              : t<string>(screen.loginInformation.descriptionSocial, {
                  social: user?.authProvider,
                })}
          </Text>
        </Box>
      </Flex>
      {user?.authProvider === AuthProviders.Email && (
        <form onSubmit={onSubmit}>
          <Flex flexDirection="column" my={3}>
            <Text fontSize="sm" fontWeight={500}>
              {t<string>(screen.loginInformation.email)}
            </Text>
            <Text>{user?.email}</Text>
          </Flex>
          <FormControl isInvalid={Boolean(errors.password)}>
            <FormLabel fontSize="sm" htmlFor="password">
              {t<string>(screen.loginInformation.password)}
            </FormLabel>
            <Input
              id="password"
              isDisabled={isLoading}
              type="password"
              {...register("password", {
                required: true,
                validate: { passwordValidation: validatePassword },
              })}
            />
            <FormErrorMessage>
              {errors.password?.type &&
                touchedFields.password &&
                t<string>(translations.validation[errors.password?.type])}
            </FormErrorMessage>
            <PasswordValidator value={currentPassword} />
          </FormControl>
          <br />
          <FormControl isInvalid={Boolean(errors.confirmPassword)}>
            <FormLabel fontSize="sm" htmlFor="confirmPassword">
              {t<string>(screen.loginInformation.confirmPassword)}
            </FormLabel>
            <Input
              id="confirmPassword"
              isDisabled={isLoading}
              type="password"
              {...register("confirmPassword", {
                validate: { passwordsMatch: validateConfirmPassword },
              })}
            />
            <FormErrorMessage>
              {errors.confirmPassword?.type &&
                touchedFields.confirmPassword &&
                t<string>(
                  translations.validation[errors.confirmPassword?.type]
                )}
            </FormErrorMessage>
          </FormControl>
          <Flex my={4}>
            <Button
              colorScheme="blue"
              isDisabled={isChangePasswordDisabled}
              isLoading={isLoading}
              size="sm"
              type="submit"
            >
              {t<string>(screen.loginInformation.changePassword)}
            </Button>
          </Flex>
        </form>
      )}
    </Flex>
  );
};
