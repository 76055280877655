import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Td,
  Tr,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { TJob } from "../../../../api/modules/Jobs/types";
import { TStatics } from "../../../../api/modules/Statics/types";
import { ResidentialCapacity } from "../../Job.types";

type Props = {
  job?: TJob;
  statics?: TStatics;
};

export const PropertyDetails = (props: Props) => {
  const screen = translations.screens.job.property;
  const { statics, job } = props;

  const lastPompOut = useMemo(
    () =>
      statics?.lastPumpoutOptions.find((item) => item.id === job?.lastPumpOutId)
        ?.description,
    [job, statics]
  );
  const reasons = useMemo(
    () =>
      job?.jobReasonIds.reduce((acc, reasonId) => {
        const currentReason = statics?.jobReasons.find(
          (reason) => reason.id === reasonId
        )?.description;
        if (currentReason) {
          acc += `${acc.length ? ", " : ""}${currentReason}`;
        }
        return acc;
      }, ""),
    [job, statics]
  );
  const residentialCapacity = useMemo(
    () =>
      statics?.residentialCapacityOptions.find(
        (item) => item.id === job?.residentialCapacity
      )?.description,
    [job, statics]
  );
  const commercialCapacity = useMemo(
    () =>
      statics?.commercialCapacityOptions.find(
        (item) => item.id === parseInt(job?.commercialCapacity as string)
      )?.description,
    [job, statics]
  );

  return (
    <Flex flexDirection="column">
      <Flex mb={1} mt={3}>
        <Text fontSize="sm" fontWeight={600}>
          {t<string>(screen.propertyInfo)}
        </Text>
      </Flex>
      <TableContainer maxWidth="100%" width="100%">
        <Table size="sm" variant="unstyled" whiteSpace="break-spaces">
          <Tbody fontSize="sm">
            <Tr>
              <Td color="gray.500" width="250px">
                {t<string>(screen.propertyType)}
              </Td>
              <Td>
                {job?.propertyUse
                  ? t<string>(screen.commercial)
                  : t<string>(screen.residential)}
              </Td>
            </Tr>
            <Tr>
              <Td color="gray.500" width="250px">
                {t<string>(screen.lastPumpOut)}
              </Td>
              <Td>{lastPompOut || "-"}</Td>
            </Tr>
            <Tr>
              <Td color="gray.500" width="250px">
                {t<string>(screen.reasons)}
              </Td>
              <Td>{reasons}</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {job?.propertyUse && (
        <>
          <br />
          <Text fontWeight="600" size="sm">
            {t<string>(screen.residentialInformation)}
          </Text>
          <TableContainer maxWidth="100%" width="100%">
            <Table size="sm" variant="unstyled" whiteSpace="break-spaces">
              <Tbody fontSize="sm">
                <Tr>
                  <Td color="gray.500" width="250px">
                    {t<string>(screen.numberOfResidents)}
                  </Td>
                  <Td>{job?.residentialInfoNumberOfCurrentResidents || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500" width="250px">
                    {t<string>(screen.designFlowTankSizeBedrooms)}
                  </Td>
                  <Td>{residentialCapacity || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500" width="250px">
                    {t<string>(
                      screen[
                        job?.residentialCapacity ===
                        ResidentialCapacity.Bedrooms
                          ? "bedrooms"
                          : "gallons"
                      ]
                    )}
                  </Td>
                  <Td>{job?.residentialInfoGdpRange || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500">
                    {t<string>(screen.lastDateOfOccupancy)}
                  </Td>
                  <Td>{job?.residentialInfoLastDateOfOccupancy || "-"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      {!job?.propertyUse && (
        <>
          <br />
          <Text fontWeight="600">
            {t<string>(screen.commercialInformation)}
          </Text>
          <TableContainer maxWidth="100%" width="100%">
            <Table variant="simple">
              <Tbody fontSize="sm">
                <Tr>
                  <Td color="gray.500">
                    {t<string>(screen.typeOfEstablishment)}
                  </Td>
                  <Td>{job?.commercialInfoTypeOfEstablishment || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500">
                    {t<string>(screen.designFlowOrTankSize)}
                  </Td>
                  <Td>{commercialCapacity || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500">{t<string>(screen.gallons)}</Td>
                  <Td>{job?.commercialInfoGdpRange || "-"}</Td>
                </Tr>
                <Tr>
                  <Td color="gray.500">
                    {t<string>(screen.lastDateOfOccupancy)}
                  </Td>
                  <Td>{job?.commercialInfoLastDateOfUse || "-"}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
    </Flex>
  );
};
