import React, { useCallback, useContext, useState } from "react";
import { ManifestsPresentation } from "./Manifests.presentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { ModalsContext } from "../../../contexts/ModalsContext/ModalsContext";
import { useDumpingLogs } from "../../../api/modules/DumpingLogs";
import { CreateManifestModal } from "./CreateManifestModal";
import { useUser } from "../../../api/modules/User";

export const Manifests = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { data: userData } = useUser();
  const [isDownloading, setIsDownloading] = useState(false);
  const { createManifest } = useContext(ModalsContext);

  const manifestQuery = useDumpingLogs({
    companyId: userData?.companyId,
    startDate: "",
    endDate: "",
  });

  const { data: companyDumpingLogs, isLoading: isLoading } =
    manifestQuery || {};

  const onDownloadClick = useCallback(async (fileUrl: string) => {
    setIsDownloading(true);
    try {
      const response = await fetch(fileUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileUrl.split("/").pop() || "downloaded-file";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setIsDownloading(false);
    }
  }, []);

  const openPdfInNewTab = useCallback(
    (fileUrl: string) => () => {
      window.open(fileUrl, "_blank");
    },
    []
  );

  return (
    <>
      <ManifestsPresentation
        breadcrumbsRef={breadcrumbsRef}
        isDownloading={isDownloading}
        isLoading={Boolean(isLoading)}
        list={companyDumpingLogs}
        pageTitleRef={pageTitleRef}
        onDownloadClick={onDownloadClick}
        onInviteModalOpen={createManifest.onOpen}
        onPdfNewTabClick={openPdfInNewTab}
      />
      <CreateManifestModal />
    </>
  );
};
