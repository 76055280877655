import { TStatics } from "../../api/modules/Statics/types";

export const getRolesDisplayNames = (roles?: string[], statics?: TStatics) => {
  return (roles || []).reduce<string[]>((acc, item) => {
    const currentRole = statics?.roles.find((i) => i.roleName === item);
    if (currentRole) {
      acc.push(currentRole.displayName);
    }
    return acc;
  }, []);
};
