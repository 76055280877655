import { useEffect } from "react";
import { useStatics } from "../api/modules/Statics";

export const useInitializeAppleAuth = () => {
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();

  useEffect(() => {
    if (
      typeof AppleID === "undefined" ||
      !staticsData?.appleClientId ||
      !staticsData?.appleRedirectUrl
    )
      return;

    AppleID.auth.init({
      clientId: staticsData.appleClientId,
      scope: "email name",
      redirectURI: window.location.origin,
      usePopup: true,
    });
  }, [typeof AppleID, isLoadingStatics, staticsData]);
};
