import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { JobsPresentation } from "./Jobs.presentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useStatics } from "../../../api/modules/Statics";
import { usePagination } from "../../../hooks/usePagination";
import { STATUS_OPTIONS, allMembersOption } from "./constants";
import { useJobsByStatus } from "../../../api/modules/JobsByStatus";
import { useUser } from "../../../api/modules/User";
import { useTeam } from "../../../api/modules/Team";
import { useNavigate } from "react-router-dom";
import { JobStatus } from "../../../api/modules/Jobs/types";
import { Routing } from "../../../constants/routing";
import { Roles } from "../../../constants/roles";

export const Jobs = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const pagination = usePagination();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS_OPTIONS[0].value);
  const { data: userData } = useUser();
  const { data: teamData } = useTeam();
  const [teamMember, setTeamMember] = useState<number | undefined>(
    allMembersOption.userId
  );

  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { isLoading: isLoadingJobs, data: jobsData } = useJobsByStatus({
    itemsPerPage: pagination.itemsPerPage,
    currentPage: pagination.currentPage,
    contractorId: userData?.roles.includes(Roles.ContractorAdmin)
      ? teamMember
      : userData?.userId,
    companyId: userData?.company?.companyId,
    status: status,
  });

  useEffect(() => {
    pagination.setTotalItems(jobsData?.totalJobsCount ?? 0);
  }, [pagination, jobsData?.totalJobsCount]);

  const handleChangeStatus = useCallback(
    (value: JobStatus) => {
      setStatus(value);
      pagination.setCurrentPage(1);
    },
    [setStatus, pagination]
  );

  const handleChangeTeamMember = useCallback(
    (e: SyntheticEvent<HTMLSelectElement, Event>) => {
      setTeamMember(+e.currentTarget.value ?? undefined);
      pagination.setCurrentPage(1);
    },
    [setTeamMember, pagination]
  );

  const handleClickJob = useCallback(
    (jobId: string | number) => () => {
      navigate(`/job/${jobId}`);
    },
    [navigate]
  );
  const handleViewAvailableJobs = useCallback(() => {
    navigate(Routing.AvailableJobs);
  }, [navigate]);

  return (
    <>
      <JobsPresentation
        breadcrumbsRef={breadcrumbsRef}
        isLoading={isLoadingJobs || isLoadingStatics}
        jobsData={jobsData}
        pageTitleRef={pageTitleRef}
        pagination={pagination}
        statics={staticsData}
        status={status}
        team={teamData}
        teamMember={teamMember}
        onChangeStatus={handleChangeStatus}
        onChangeTeamMember={handleChangeTeamMember}
        onClickJob={handleClickJob}
        onViewAvailableJobs={handleViewAvailableJobs}
      />
    </>
  );
};
