import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { t } from "i18next";
import { translations } from "../../../../../../i18n/translations";

export const ResultScreen = () => {
  const screen = translations.screens.pumperPanel.manifests.createManifestModal;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <br />
      <Text fontSize="xl" fontWeight={600}>
        {t<string>(screen.result.title)}
      </Text>
      <br />
      <Text color="gray.500" textAlign="center">
        {t<string>(screen.result.description)}
      </Text>
      <br />
      <br />
    </Flex>
  );
};
