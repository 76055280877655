import React from "react";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Text,
  SimpleGrid,
  Flex,
  Box,
} from "@chakra-ui/react";
import { PresentationProps } from "./AcceptInvitation.types";
import { translations } from "../../i18n/translations";
import { t } from "i18next";
import BgImg from "./assets/bg.png";
import { Logo } from "../../components/Logo/Logo";
import { AiFillApple as AppleIcon } from "react-icons/ai";

export const AcceptInvitationPresentation = (props: PresentationProps) => {
  const { register, onSubmit, errors, isSubmitting, onAppleSignIn } = props;
  const screen = translations.screens.acceptInvitation;
  return (
    <SimpleGrid
      columns={[1, 1, 2, 2]}
      minH={["200px", "200px", "100vh", "100vh"]}
      spacing={0}
    >
      <Flex
        alignItems={["center", "center", "flex-start", "flex-start"]}
        bgColor="#000000db"
        bgImage={`url(${BgImg})`}
        bgSize="cover"
        justifyContent={["center", "center", "flex-start", "flex-start"]}
        p={8}
      >
        <Logo />
      </Flex>
      <Flex
        alignItems={["center", "center", "flex-start", "flex-start"]}
        flexDirection="column"
        justifyContent="center"
        pt={["50px", "50px", 0, 0]}
        px="20%"
      >
        <Flex flexDirection="column">
          <Text fontSize="30px" fontWeight={700}>
            {t<string>(screen.welcome)}
          </Text>
          <Text color="gray.400" fontSize="16px" fontWeight={500}>
            {t<string>(screen.description)}
          </Text>
        </Flex>
        <br />
        <form style={{ minWidth: "300px" }} onSubmit={onSubmit}>
          <FormControl isInvalid={Boolean(errors.email?.message)}>
            <FormLabel htmlFor="name">{t<string>(screen.form.email)}</FormLabel>
            <Input
              id="email"
              {...register("email", {
                required: "This is required",
                minLength: { value: 4, message: "Minimum length should be 4" },
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <br />
          <FormControl isInvalid={Boolean(errors.password?.message)}>
            <FormLabel htmlFor="name">
              {t<string>(screen.form.password)}
            </FormLabel>
            <Input
              id="password"
              type="password"
              {...register("password", {
                required: "This is required",
                minLength: { value: 4, message: "Minimum length should be 4" },
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <br />
          <FormControl isInvalid={Boolean(errors.inviteCode?.message)}>
            <FormLabel htmlFor="name">
              {t<string>(screen.form.inviteCode)}
            </FormLabel>
            <Input
              id="inviteCode"
              {...register("inviteCode", {
                required: "This is required",
                minLength: { value: 4, message: "Minimum length should be 4" },
              })}
            />
            <FormErrorMessage>
              {errors.inviteCode && errors.inviteCode.message}
            </FormErrorMessage>
          </FormControl>
          <Flex flexDirection="column" gap={2} mt={4}>
            <div id="google-button" />
            <Button
              fontFamily='"Google Sans",arial,sans-serif'
              fontSize="15px"
              fontWeight={500}
              size="md"
              variant="outline"
              onClick={onAppleSignIn}
            >
              <Box left="10px" position="absolute">
                <AppleIcon fontSize="22px" />
              </Box>
              <Flex pl="16px"> {t<string>(screen.form.signInWithApple)}</Flex>
            </Button>
          </Flex>
          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            mt={4}
            type="submit"
          >
            {t<string>(screen.form.accept)}
          </Button>
        </form>
      </Flex>
    </SimpleGrid>
  );
};
