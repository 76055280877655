import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getUser } from "./getUser";
import { TUser } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";

export const useUser = () => {
  const toast = useToast();
  return useQuery<TUser>(Queries.User, getUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      toast({
        title: "Error",
        description: t<string>(translations.errors.user_error),
        status: "error",
        position: "top-right",
      });
    },
  });
};
