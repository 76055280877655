import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { putUpdateUser } from "./putUpdateUser";
import { TUpdateUserBody } from "./types";
import { translations } from "../../../i18n/translations";
import { Queries } from "../../../constants/queries";

export const useUpdateUser = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, TUpdateUserBody>(
    (payload) => putUpdateUser(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.update_profile_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(Queries.User);
      },
    }
  );
};
