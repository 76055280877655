import React from "react";
import { t } from "i18next";
import {
  BsCheckCircle as CheckIcon,
  BsXCircle as ErrorIcon,
} from "react-icons/bs";
import { Props } from "./PasswordValidator.types";
import { VALIDATION_RULES } from "./constants";
import { translations } from "../../i18n/translations";
import { Flex, useToken } from "@chakra-ui/react";

export const PasswordValidator = (props: Props) => {
  const { value } = props;
  const { common } = translations;
  const [positive, negative] = useToken("colors", ["green.500", "red.500"]);
  return (
    <Flex flexDirection="column" fontSize="12px">
      <Flex my={2}>{t<string>(common.passwordValidation.title)}</Flex>
      {Object.entries(VALIDATION_RULES).map(([key, regex]) => {
        const isValid = regex.test(value || "");
        return (
          <Flex key={key} alignItems="center">
            {isValid ? (
              <CheckIcon color={positive} />
            ) : (
              <ErrorIcon color={negative} />
            )}
            <Flex ml={3}>{t<string>(common.passwordValidation[key])}</Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
