import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { ProfilePresentation } from "../Common/ProfilePresentation";
import { PortalsContext } from "../../../contexts/PortalsContext";
import { useStatics } from "../../../api/modules/Statics";
import {
  ProfileCompanyFormData,
  ProfileFormData,
  ProfileServiceFormData,
} from "../Common/ProfilePresentation/Profile.types";
import { useUserById } from "../../../api/modules/UserById";
import { useParams } from "react-router-dom";
import { ModalsContext } from "../../../contexts/ModalsContext";
import { useUpdateUserById } from "../../../api/modules/UpdateUserById";
import { useUpdateCompany } from "../../../api/modules/UpdateCompany";
import { useCompany } from "../../../api/modules/Company";
import { useUpdateCompanyJobTypes } from "../../../api/modules/UpdateCompanyJobTypes";

export const User = () => {
  const { pageTitleRef, breadcrumbsRef } = useContext(PortalsContext);
  const { updateRoles, removeFromTeam } = useContext(ModalsContext);
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();
  const { userId } = useParams();
  const { mutateAsync, isLoading } = useUpdateUserById();
  const { data: companyData } = useCompany();
  const { mutateAsync: handleUpdateCompany, isLoading: isUpdatingCompany } =
    useUpdateCompany();
  const { data } = useUserById(userId!);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditCompanyMode, setIsEditCompanyMode] = useState(false);
  const [isEditServiceMode, setIsEditServiceMode] = useState(false);
  const {
    mutateAsync: updateCompanyJobTypes,
    isLoading: isUpdatingCompanyJobTypes,
  } = useUpdateCompanyJobTypes();
  const [formData, setFormData] = useState<ProfileFormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    zip: "",
    state: "",
    septicSystemInspectionsLicense: "",
    plumbingLicense: "",
  });
  const [companyFormData, setCompanyFormData] =
    useState<ProfileCompanyFormData>({
      companyName: "",
      haveOwnInsurance: false,
      businessPhone: "",
      businessEmail: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      zip: "",
      state: "",
    });

  const [serviceFormData, setServiceFormData] =
    useState<ProfileServiceFormData>({
      companyLocations: [],
    });

  const handleSetEditMode = useCallback(() => {
    setIsEditMode(true);
    setFormData({
      firstName: data?.firstName || "",
      lastName: data?.lastName || "",
      phoneNumber: data?.phoneNumber || "",
      email: data?.email || "",
      addressLine1: data?.addressLine1 || "",
      addressLine2: data?.addressLine2 || "",
      city: data?.city || "",
      zip: data?.zip || "",
      state: data?.state || "",
      septicSystemInspectionsLicense:
        data?.septicSystemInspectionsLicense || "",
      plumbingLicense: data?.plumbingLicense || "",
    });
  }, [setIsEditMode, setFormData, data]);
  const handleSetCompanyEditMode = useCallback(() => {
    setIsEditCompanyMode(true);
    setCompanyFormData({
      companyName: data?.company?.companyName || "",
      haveOwnInsurance: data?.company?.haveOwnInsurance || false,
      businessPhone: data?.company?.businessPhone || "",
      businessEmail: data?.company?.businessEmail || "",
      addressLine1: data?.company?.addressLine1 || "",
      addressLine2: data?.company?.addressLine2 || "",
      city: data?.company?.city || "",
      zip: data?.company?.zip || "",
      state: data?.company?.state || "",
    });
  }, [setCompanyFormData, setIsEditCompanyMode, data?.company]);

  const handleSetServiceEditMode = useCallback(() => {
    setIsEditServiceMode(true);
    setServiceFormData({
      companyLocations: companyData?.companyLocations || [],
    });
  }, [setIsEditServiceMode, setServiceFormData, companyData]);

  const handleCancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const handleCancelCompanyEditMode = useCallback(() => {
    setIsEditCompanyMode(false);
  }, [setIsEditCompanyMode]);

  const handleCancelServiceEditMode = useCallback(() => {
    setIsEditServiceMode(false);
  }, [setIsEditServiceMode]);

  const handleEditField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setFormData]
  );
  const handleEditCompanyField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCompanyFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setCompanyFormData]
  );
  const handleEditServiceField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setServiceFormData((state) => ({ ...state, [field]: e.target.value }));
    },
    [setServiceFormData]
  );

  const handleEditCompanyCheckboxField = useCallback(
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      setCompanyFormData((state) => ({ ...state, [field]: e.target.checked }));
    },
    [setCompanyFormData]
  );

  const handleSaveProfile = useCallback(async () => {
    await mutateAsync({ ...formData, userId });
    setIsEditMode(false);
  }, [formData, mutateAsync, setIsEditMode, userId]);

  const handleSaveCompany = useCallback(async () => {
    await handleUpdateCompany({
      ...data?.company,
      ...companyFormData,
    });
    setIsEditCompanyMode(false);
    setIsEditServiceMode(false);
  }, [
    data?.company,
    setIsEditCompanyMode,
    handleUpdateCompany,
    companyFormData,
  ]);
  const handleSaveService = useCallback(async () => {
    await handleUpdateCompany({
      ...data?.company,
      ...serviceFormData,
    });
    setIsEditCompanyMode(false);
    setIsEditServiceMode(false);
  }, [
    serviceFormData,
    data?.company,
    setIsEditCompanyMode,
    handleUpdateCompany,
  ]);

  const handleChangeServiceType = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (e.target.checked) {
        const updatedJobTypes = (companyData?.companyJobTypes || []).concat(
          parseInt(e.target.name)
        );
        updateCompanyJobTypes(updatedJobTypes);
      } else {
        const updatedJobTypes = (companyData?.companyJobTypes || []).filter(
          (service) => service !== parseInt(e.target.name)
        );
        updateCompanyJobTypes(updatedJobTypes);
      }
    },
    [companyData?.companyJobTypes, updateCompanyJobTypes]
  );
  const serviceOptions = (staticsData?.jobTypes || []).reduce<
    { label: string; value: number }[]
  >((acc, service) => {
    acc.push({ value: service.id, label: service.title });

    (service.relatedJobTypes || []).forEach((relatedJob) => {
      acc.push({ value: relatedJob.id, label: relatedJob.title });
    });
    return acc;
  }, []);
  const isServiceDetailsAvailable = data?.companyId === companyData?.companyId;

  return (
    <ProfilePresentation
      activeServiceOptions={companyData?.companyJobTypes || []}
      breadcrumbsRef={breadcrumbsRef}
      companyData={companyData}
      companyFormData={companyFormData}
      data={data}
      formData={formData}
      inAccountWizard={false}
      isCompanyEditable={true}
      isEditCompanyMode={isEditCompanyMode}
      isEditMode={isEditMode}
      isEditServiceMode={isEditServiceMode}
      isEditable={true}
      isLoading={isLoadingStatics}
      isServiceDetailsAvailable={isServiceDetailsAvailable}
      isStripeConnected={false}
      isUpdatingCompany={isUpdatingCompany}
      isUpdatingCompanyJobTypes={isUpdatingCompanyJobTypes}
      isUpdatingProfile={isLoading}
      pageTitleRef={pageTitleRef}
      removeFromTeam={removeFromTeam}
      serviceFormData={serviceFormData}
      serviceOptions={serviceOptions}
      statics={staticsData}
      updateRolesModal={updateRoles}
      onAddCompanyLocation={() => {
        return;
      }}
      onCancel={() => () => {
        return;
      }}
      onCancelCompanyEditMode={handleCancelCompanyEditMode}
      onCancelEditMode={handleCancelEditMode}
      onCancelServiceEditMode={handleCancelServiceEditMode}
      onChangeServiceType={handleChangeServiceType}
      onEditCompanyCheckboxField={handleEditCompanyCheckboxField}
      onEditCompanyField={handleEditCompanyField}
      onEditField={handleEditField}
      onEditServiceField={handleEditServiceField}
      onEditServiceLocationField={() => () => {
        return;
      }}
      onRemoveCompanyLocation={() => () => {
        return;
      }}
      onSaveCompany={handleSaveCompany}
      onSaveProfile={handleSaveProfile}
      onSaveService={handleSaveService}
      onSetEditCompanyMode={handleSetCompanyEditMode}
      onSetEditMode={handleSetEditMode}
      onSetEditServiceMode={handleSetServiceEditMode}
    />
  );
};
