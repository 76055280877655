import { Endpoints } from "../../../constants/endpoints";
import { TCompanyTruckRaw } from "./types";
import client from "../../client";

export const getCompanyTrucks = (companyId: number) => async () => {
  const { data } = await client.get<TCompanyTruckRaw>(
    Endpoints.getCompanyTruck(companyId!)
  );
  return data;
};
