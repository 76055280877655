import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getJob } from "./getJob";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { TJob } from "../Jobs/types";

export const useJob = (jobId?: number) => {
  const toast = useToast();
  return useQuery<TJob>(Queries.Job(jobId), getJob(jobId!), {
    staleTime: Infinity,
    cacheTime: Infinity,
    onError: () => {
      toast({
        title: "Error",
        description: t<string>(translations.errors.job_error),
        status: "error",
        position: "top-right",
      });
    },
    enabled: Boolean(jobId),
  });
};
