import { CredentialResponse } from "google-one-tap";
import { useEffect } from "react";
import { useStatics } from "../api/modules/Statics";

type Props = {
  callback: (credentialResponse: CredentialResponse) => void;
  context?: "signin" | "signup" | "use";
};
export const useInitializeGoogleAuth = (props: Props) => {
  const { callback, context } = props;
  const { isLoading: isLoadingStatics, data: staticsData } = useStatics();

  useEffect(() => {
    if (typeof google === "undefined" || isLoadingStatics) return;
    const googleBtn = document.getElementById("google-button");
    google.accounts.id.initialize({
      client_id: staticsData?.googleClientId || "",
      callback,
      context: context || "signin",
    });

    if (googleBtn) {
      if (context === "signup") {
        google.accounts.id.renderButton(googleBtn, {
          width: 400,
          logo_alignment: "center",
          text: "signup_with",
        });
      } else {
        google.accounts.id.renderButton(googleBtn, {
          width: 403,
          logo_alignment: "left",
        });
      }
    }
    google.accounts.id.prompt();
  }, [typeof google, isLoadingStatics, staticsData]);
};
