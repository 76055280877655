import { useQuery } from "react-query";
import { Queries } from "../../../constants/queries";
import { getCompany } from "./getCompany";
import { TCompany } from "./types";
import { t } from "i18next";
import { translations } from "../../../i18n/translations";
import { useToast } from "@chakra-ui/react";
import { useUser } from "../User";

export const useCompany = () => {
  const toast = useToast();
  const { data } = useUser();

  return useQuery<TCompany>(
    Queries.Company(data?.companyId),
    getCompany(data?.companyId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.company_error),
          status: "error",
          position: "top-right",
        });
      },
      enabled: Boolean(data) && Boolean(data?.companyId),
    }
  );
};
