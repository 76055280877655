import React, { createContext, ReactNode, RefObject, useRef } from "react";

type Props = {
  children: ReactNode;
};

export type PortalsContextType = {
  pageTitleRef: RefObject<HTMLParagraphElement> | undefined;
  breadcrumbsRef: RefObject<HTMLDivElement> | undefined;
};

export const PortalsContext = createContext<PortalsContextType>(
  {} as PortalsContextType
);

export const PortalsContextWrapper = (props: Props) => {
  const { children } = props;
  const pageTitleRef = useRef<HTMLParagraphElement>(null);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  return (
    <PortalsContext.Provider value={{ pageTitleRef, breadcrumbsRef }}>
      {children}
    </PortalsContext.Provider>
  );
};
