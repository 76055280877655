import React, { useCallback, useContext, useState } from "react";
import { ModalsContext } from "../../../../contexts/ModalsContext";
import { CreateManifestModalPresentation } from "./CreateManifestModal.presentation";
import { CreateManifestSteps } from "./CreateManifestModal.types";
import { useCreateManifest } from "../../../../api/modules/CreateManifest";

export const CreateManifestModal = () => {
  const { createManifest } = useContext(ModalsContext);
  const [step, setStep] = useState<CreateManifestSteps>(
    CreateManifestSteps.Details
  );
  const { mutateAsync: handleRequestCreateManifest, isLoading } =
    useCreateManifest();

  const handleCloseModal = useCallback(() => {
    createManifest.onClose();
    setStep(CreateManifestSteps.Details);
  }, [createManifest]);

  const handleCreateManifest = useCallback(
    (startDate: string, endDate: string) => {
      handleRequestCreateManifest(
        {
          startDate,
          endDate,
        },
        {
          onSuccess: () => {
            setStep(CreateManifestSteps.Result);
          },
        }
      );
    },
    [handleRequestCreateManifest]
  );

  return (
    <CreateManifestModalPresentation
      isLoading={isLoading}
      isOpen={createManifest.isOpen}
      step={step}
      onClose={handleCloseModal}
      onCreateManifest={handleCreateManifest}
    />
  );
};
