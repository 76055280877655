import { PortalsContextType } from "../../../contexts/PortalsContext";
import { TJobs } from "../../../api/modules/Jobs/types";
import { TStatics } from "../../../api/modules/Statics/types";
import { PaginationType } from "../../../types/common/Pagination";
import { ChangeEvent, SyntheticEvent } from "react";

export type PresentationProps = {
  pageTitleRef: PortalsContextType["pageTitleRef"];
  breadcrumbsRef: PortalsContextType["breadcrumbsRef"];
  isLoading: boolean;
  isLoadingProfile: boolean;
  distance: number;
  mode: Modes;
  zip: string;
  jobTypeId: number | undefined;
  jobsData?: TJobs;
  statics?: TStatics;
  pagination: PaginationType;
  onClickJob: (jobId: number) => () => void;
  onChangeZip: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeJobType: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
  onChangeDistance: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
  onChangeMode: (mode: Modes) => () => void;
  mapCenterCoords: {
    lat?: number;
    lng?: number;
  };
};

export enum Modes {
  Table = "table",
  Map = "map",
}
