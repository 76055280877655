import React, { useCallback, useContext, useState } from "react";
import { ModalsContext } from "../../contexts/ModalsContext";
import { RemoveFromTeamSteps } from "./RemoveFromTeam.types";
import { useRemoveFromTeam } from "../../api/modules/RemoveFromTeam";
import { useUserById } from "../../api/modules/UserById";
import { RemoveFromTeamPresentation } from "./RemoveFromTeam.presentation";

type Props = {
  userId?: number | null;
  companyId?: number | null;
};

export const RemoveFromTeam = (props: Props) => {
  const { removeFromTeam } = useContext(ModalsContext);
  const [step, setStep] = useState<RemoveFromTeamSteps>(
    RemoveFromTeamSteps.Details
  );
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const { mutateAsync: handleRemoveFromTeam, isLoading: isRemoving } =
    useRemoveFromTeam();
  const { data, isLoading: isLoadingUser } = useUserById(props.userId!);
  const handleCloseModal = useCallback(() => {
    removeFromTeam.onClose();
    setStep(RemoveFromTeamSteps.Details);
  }, [removeFromTeam]);

  const handleRemove = useCallback(() => {
    if (!props.userId || !props.companyId) return;
    handleRemoveFromTeam(
      {
        userId: props.userId,
        companyId: props.companyId,
      },
      {
        onSuccess: () => {
          setStep(RemoveFromTeamSteps.Result);
        },
      }
    );
  }, [handleRemoveFromTeam, setStep, props]);

  const handleChangeAccepted = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setIsAccepted(checked);
    },
    [setIsAccepted]
  );

  return (
    <RemoveFromTeamPresentation
      isAccepted={isAccepted}
      isLoadingUser={isLoadingUser}
      isOpen={removeFromTeam.isOpen}
      isRemoving={isRemoving}
      step={step}
      userData={data}
      onChangeAccepted={handleChangeAccepted}
      onClose={handleCloseModal}
      onRemove={handleRemove}
    />
  );
};
