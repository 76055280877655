import { InviteTeamMemberModalPresentation } from "./InviteTeamMemberModal.presentation";
import React, { useContext, useState } from "react";
import { ModalsContext } from "../../contexts/ModalsContext";
import { useForm } from "react-hook-form";
import { useInviteTeamMember } from "../../api/modules/InviteTeamMember";
import {
  AdditionalDataFormType,
  InviteFormType,
  InviteSteps,
} from "./InviteTeamMemberModal.types";
import { useStatics } from "../../api/modules/Statics";
import { useUpdateUserById } from "../../api/modules/UpdateUserById";

export const InviteTeamMemberModal = () => {
  const { inviteTeamMember } = useContext(ModalsContext);
  const [step, setStep] = useState<InviteSteps>(InviteSteps.InviteForm);
  const [createdUserId, setCreatedUserId] = useState<
    string | number | undefined
  >(undefined);
  const [createdUserFirstName, setCreatedUserFirstName] = useState<string>("");
  const { mutateAsync: mutateAsyncInvite, isLoading: isLoadingInviteRequest } =
    useInviteTeamMember();
  const {
    mutateAsync: mutateAsyncAddData,
    isLoading: isLoadingAddDataRequest,
  } = useUpdateUserById();
  const { data: staticsData } = useStatics();

  const {
    handleSubmit: handleSubmitInvite,
    register: registerInvite,
    formState: { errors: errorsInvite },
  } = useForm<InviteFormType>();

  const {
    handleSubmit: handleSubmitAddData,
    register: registerAddData,
    formState: { errors: errorsAddData },
  } = useForm<AdditionalDataFormType>();

  const onSubmitInvite = handleSubmitInvite(async (data) => {
    setCreatedUserFirstName(data.firstName);
    await mutateAsyncInvite(
      {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        roles: data.roles,
      },
      {
        onSuccess: (result) => {
          setCreatedUserId(result.userData.userId);
          setStep(InviteSteps.AdditionalData);
        },
      }
    );
  });

  const onAddData = handleSubmitAddData(async (data) => {
    await mutateAsyncAddData(
      {
        addressLine1: data.addressLine1,
        zip: data.zip,
        phoneNumber: data.phoneNumber,
        userId: createdUserId,
      },
      {
        onSuccess: () => {
          setStep(InviteSteps.Result);
        },
      }
    );
  });

  return (
    <InviteTeamMemberModalPresentation
      addDataErrors={errorsAddData}
      addDataRegister={registerAddData}
      createdUserFirstName={createdUserFirstName}
      inviteErrors={errorsInvite}
      inviteRegister={registerInvite}
      isLoadingAddDataRequest={isLoadingAddDataRequest}
      isLoadingInviteRequest={isLoadingInviteRequest}
      isOpen={inviteTeamMember.isOpen}
      rolesOptions={staticsData?.roles}
      step={step}
      onAddData={onAddData}
      onClose={inviteTeamMember.onClose}
      onInvite={onSubmitInvite}
    />
  );
};
