import React from "react";
import { Divider, Flex, Link, Text, useToken } from "@chakra-ui/react";
import { t } from "i18next";

import { Link as RouterLink, Outlet } from "react-router-dom";
import { translations } from "../../../i18n/translations";
import { Routing } from "../../../constants/routing";
import Layout from "../../../components/Layout";
import FullPageContent from "../../../components/FullPageContent";

type Props = {
  onLogout: () => void;
};

export const SettingsWrapperPresentation = (props: Props) => {
  const { onLogout } = props;
  const screen = translations.screens.settings;
  const [gray100] = useToken("colors", ["gray.100"]);
  return (
    <Layout>
      <FullPageContent>
        <Flex flex={1} flexDirection={["column", "column", "column", "row"]}>
          <Flex mb={[3, 3, 3, 0]} width={["100%", "100%", "100%", "150px"]}>
            <Flex flexDirection="column" flexGrow={1}>
              <Text fontWeight={600}>{t<string>(screen.menu.settings)}</Text>
              <Flex flexDirection={["row", "row", "row", "column"]}>
                <Flex mt={4}>
                  <Link
                    as={RouterLink}
                    color="gray.400"
                    fontSize="sm"
                    to={Routing.Settings.LoginInformation.Absolute}
                  >
                    {t<string>(screen.menu.loginInformation)}
                  </Link>
                </Flex>
                <Flex ml={[3, 3, 3, 0]} mt={4}>
                  <Link color="gray.400" fontSize="sm" onClick={onLogout}>
                    {t<string>(screen.menu.logout)}
                  </Link>
                </Flex>
              </Flex>
            </Flex>
            <Divider color={gray100} orientation="vertical" />
          </Flex>
          <Flex px={[0, 0, 0, 4]}>
            <Outlet />
          </Flex>
        </Flex>
      </FullPageContent>
    </Layout>
  );
};
