export const Routing = {
  Login: "/login",
  Signup: "/signup",
  ForgotPassword: "/forgotPassword",
  AcceptInvitation: "/acceptInvitation",
  Root: "/",
  Profile: "/profile",
  Settings: {
    Root: "/settings",
    LoginInformation: {
      Absolute: "/settings/loginInformation",
      Relative: "loginInformation",
    },
  },
  User: "/user/:userId",
  Jobs: "/jobs",
  AvailableJobs: "/availableJobs",
  Manifests: "/manifests",
  Dumpout: "/dumpouts",
  Truck: "/trucks",
  Job: "/job/:jobId",
  Team: "/team",
  PayWall: "/paywall",
  Terms: "/terms",
  Privacy: "/privacy",
};
