export const Queries = {
  User: "user",
  Company: (companyId?: string | number | null) => ["company", companyId],
  UserById: (userId?: string | number | null) => ["user", userId],
  Statics: "statics",
  MyJobs: (currentPage: number, itemsPerPage: number, status?: string) => [
    "myJobs",
    currentPage,
    itemsPerPage,
    status,
  ],
  JobsByStatus: (
    currentPage: number,
    itemsPerPage: number,
    status: string,
    companyId: number,
    contractorId?: number
  ) => ["myJobs", currentPage, itemsPerPage, status, companyId, contractorId],
  AvailableJobs: (
    currentPage: number,
    itemsPerPage: number,
    jobTypeId?: number,
    miles?: number,
    lat?: number,
    lng?: number,
    zip?: string
  ) => [
    "availableJobs",
    currentPage,
    itemsPerPage,
    jobTypeId,
    miles,
    lat,
    lng,
    zip,
  ],
  JobsByUserId: (userId: number, currentPage: number, itemsPerPage: number) => [
    "jobs",
    userId,
    currentPage,
    itemsPerPage,
  ],
  GetStripeLink: (companyId: number) => ["getStripeLink", companyId],
  Team: (companyId?: string | number | null) => ["team", companyId],
  Job: (jobId?: string | number | null) => ["job", jobId],
  Manifests: (companyId: number, startDate?: string, endDate?: string) => [
    "manifests",
    companyId,
    startDate,
    endDate,
  ],
  Dumpout: "dumpout",
  Truck: (companyId: number) => ["truck", companyId],
};
