import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./ServiceDetails.types";
import { Switch } from "@chakra-ui/react";

export const ServiceDetailsTab = (props: Props) => {
  const {
    activeServiceOptions,
    isUpdatingCompanyJobTypes,
    options,
    onChangeServiceType,
  } = props;
  const screen = translations.screens.profile;

  return (
    <>
      <br />
      <Text fontSize="lg" fontWeight={600}>
        {t<string>(screen.serviceTypes)}
      </Text>
      <Flex flexDirection="column" mt={8}>
        {options.map(({ label, value }) => (
          <Flex
            key={value}
            borderBottom="1px solid"
            borderColor="gray.200"
            fontSize="sm"
            fontWeight={500}
            justifyContent="space-between"
            pl={0}
            pr={6}
            py={4}
          >
            <Text>{label}</Text>
            <Switch
              id={value.toString()}
              isChecked={activeServiceOptions.includes(value)}
              isDisabled={isUpdatingCompanyJobTypes}
              name={value.toString()}
              onChange={onChangeServiceType}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
};
