import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TAssignJob, TPayload } from "./types";

export const postAssignJob = async ({ jobId, contractorId }: TPayload) => {
  const { data } = await client.post<{ contractorId: number }, TAssignJob>(
    Endpoints.postAssignJob(jobId),
    {
      contractorId,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
