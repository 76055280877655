import { useMutation, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { TData, TPayload } from "./types";
import { translations } from "../../../i18n/translations";
import { postAssignJob } from "./postAssignJob";

export const useAssignJob = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation<TData, unknown, TPayload>(
    (payload) => postAssignJob(payload),
    {
      onError: () => {
        toast({
          title: "Error",
          description: t<string>(translations.errors.assign_job_error),
          status: "error",
          position: "top-right",
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["availableJobs"]);
      },
    }
  );
};
