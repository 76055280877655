import React from "react";
import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { PersonPopover } from "../../../../components/PersonPopover";
import { Props } from "./CompanyAdmins.types";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";

export const CompanyAdmins = (props: Props) => {
  const { data } = props;
  const screen = translations.screens.profile;

  return (
    <TableContainer maxWidth="100%" width="100%">
      <Table variant="simple">
        <Tbody fontSize="sm">
          {data.map((item) => (
            <Tr
              key={item.email}
              _hover={{
                backgroundColor: "gray.50",
              }}
            >
              <Td paddingLeft="0">
                <PersonPopover
                  email={item.email}
                  firstName={item.firstName || ""}
                  lastName={item.lastName || ""}
                  phone={item.phoneNumber}
                  profilePicUrl={item.photoUrl}
                />
              </Td>
              <Td color="blue.500">
                {(item.email || item.phoneNumber) && (
                  <a
                    href={
                      item.email
                        ? `mailto:${item.email}`
                        : `tel:${item.phoneNumber}`
                    }
                  >
                    {t<string>(screen.contact)}
                  </a>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
