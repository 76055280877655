import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TRemoveFromTeamBody } from "./types";

export const deleteRemoveFromTeam = async ({
  userId,
  companyId,
}: Partial<TRemoveFromTeamBody>) => {
  const data = await client.remove<TRemoveFromTeamBody>(
    Endpoints.deleteRemoveFromTeam(userId!, companyId!),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
