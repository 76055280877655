import React, { useCallback, useState } from "react";
import { Box, Button, Divider, Flex, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { translations } from "../../../../i18n/translations";
import { Props } from "./PaymentDetails.types";
import { Payments } from "../Payments";
import { useGetStripeLink } from "../../../../api/modules/GetStripeLink";
import { useNavigate } from "react-router-dom";
import { Routing } from "../../../../constants/routing";

export const PaymentDetailsTab = (props: Props) => {
  const { inAccountWizard, isStripeConnected, onTabChange } = props;
  const screen = translations.screens.profile;
  const navigate = useNavigate();
  const toast = useToast();
  const { mutateAsync, isLoading } = useGetStripeLink();

  const [isNextClicked, setIsNextClicked] = useState(false);
  const handleClickCTA = useCallback(async () => {
    setIsNextClicked(true);
    const link = await mutateAsync();
    window.location.assign(link);
    setIsNextClicked(false);
  }, [mutateAsync]);

  const handleFinish = useCallback(async () => {
    const id = "completed";
    if (!toast.isActive(id)) {
      toast({
        id,
        title: "",
        description: "Account Completed",
        position: "top-right",
        status: "success",
        isClosable: true,
        duration: 10000,
        variant: "subtle",
        containerStyle: { color: "#067647" },
      });
    }
    navigate(Routing.Team);
  }, [navigate, toast]);

  return (
    <>
      <br />
      <Payments buttonName="true" />
      <Divider />
      <Box display="flex" justifyContent={"flex-end"} mt="20px">
        <Flex>
          {inAccountWizard && (
            <Flex gap={2}>
              <Button
                colorScheme="nosPurple"
                size="sm"
                variant="outline"
                onClick={() => onTabChange?.(1)}
              >
                {t<string>(screen.back)}
              </Button>
              <Button
                colorScheme="nosPurple"
                isLoading={isNextClicked || isLoading}
                size="sm"
                onClick={isStripeConnected ? handleFinish : handleClickCTA}
              >
                {t<string>(screen.next)}
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};
