import { TJob } from "../../api/modules/Jobs/types";
import { TStatics } from "../../api/modules/Statics/types";

export const getJobTypeCopy = (job?: TJob, statics?: TStatics) => {
  const { jobTypes } = statics || {};
  const currentJobType = jobTypes?.find(({ id }) => id === job?.jobTypeId);
  const relatedJobType = currentJobType?.relatedJobTypes?.find(
    ({ id }) => id === job?.relatedJobIds[0]
  );
  return {
    jobType: currentJobType?.title,
    relatedJobType: relatedJobType?.title,
    combined: `${currentJobType?.title}${
      relatedJobType ? ` (+ ${relatedJobType.title})` : ""
    }`,
  };
};
