const mapOptions = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  zoomControl: true,
  fullscreenControl: false,
  // gestureHandling: 'none',
};

export { mapOptions };
