import {
  Button,
  Flex,
  Radio,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { TTeam } from "../../../../api/modules/Team/types";

type Props = {
  currentUser?: number;
  isReassignMode?: boolean;
  onSetCurrentUser: (userId: number) => () => void;
  onAssign: () => void;
  team?: TTeam;
};

export const UserSelection = (props: Props) => {
  const { isReassignMode, team, currentUser, onSetCurrentUser, onAssign } =
    props;
  const screen = translations.screens.assignJob;
  return (
    <Flex flexDirection="column">
      <TableContainer>
        <Table size="sm" variant="simple" whiteSpace="break-spaces">
          <Thead>
            <Tr>
              <Th />
              <Th>{t<string>(screen.userSelection.table.contractor)}</Th>
              <Th textAlign="center">
                {t<string>(screen.userSelection.table.scheduledJobs)}
              </Th>
              <Th textAlign="center">
                {t<string>(screen.userSelection.table.rating)}
              </Th>
            </Tr>
          </Thead>
          <Tbody fontSize="sm">
            {(team || []).map((item) => (
              <Tr
                key={item.userId}
                _hover={{
                  backgroundColor: "gray.50",
                }}
                style={{
                  cursor: "pointer",
                }}
                onClick={onSetCurrentUser(item.userId)}
              >
                <Td py={3}>
                  <Radio
                    isChecked={item.userId === currentUser}
                    value={item.userId.toString()}
                  />
                </Td>
                <Td>{item.fullName}</Td>
                <Td textAlign="center">{item.scheduledJobs}</Td>
                <Td textAlign="center">★{item.userRating || "N/A"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {!(team || []).length && (
        <Flex justifyContent="center" mt={4}>
          <Text fontWeight={500}>{t<string>(screen.noTeamMembers)}</Text>
        </Flex>
      )}
      <Flex justifyContent="center" mt={5}>
        <Button
          colorScheme="blue"
          isDisabled={!currentUser}
          size="sm"
          onClick={onAssign}
        >
          {t<string>(
            screen.userSelection[isReassignMode ? "reassign" : "assign"]
          )}
        </Button>
      </Flex>
    </Flex>
  );
};
