import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { ModalsContext } from "../../../../contexts/ModalsContext";
import { CancelJobModalPresentation } from "./CancelJobModal.presentation";
import { CancelJobSteps } from "./CancelJobModal.types";
import { useCancelJob } from "../../../../api/modules/CancelJob";

type Props = {
  jobId?: number;
};

export const CancelJobModal = (props: Props) => {
  const { cancelJob } = useContext(ModalsContext);
  const [reason, setReason] = useState<string>("");
  const [step, setStep] = useState<CancelJobSteps>(CancelJobSteps.Details);
  const { mutateAsync: handleRequestCancelJob, isLoading } = useCancelJob();

  const handleCloseModal = useCallback(() => {
    cancelJob.onClose();
    setStep(CancelJobSteps.Details);
  }, [cancelJob]);

  const handleChangeReason = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setReason(e.currentTarget.value);
    },
    [setReason]
  );

  const handleCancelJob = useCallback(() => {
    if (!props.jobId) return;
    handleRequestCancelJob(
      {
        jobId: props.jobId,
        reason,
      },
      {
        onSuccess: () => {
          setStep(CancelJobSteps.Result);
        },
      }
    );
  }, [handleRequestCancelJob, setStep, props.jobId, reason]);

  return (
    <CancelJobModalPresentation
      isLoading={isLoading}
      isOpen={cancelJob.isOpen}
      reason={reason}
      step={step}
      onCancelJob={handleCancelJob}
      onChangeReason={handleChangeReason}
      onClose={handleCloseModal}
    />
  );
};
