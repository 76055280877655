import React from "react";
import Layout from "../../components/Layout";
import { FiPlus as PlusIcon } from "react-icons/fi";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded as DotsIcon } from "react-icons/bi";
import { t } from "i18next";
import { Routing } from "../../constants/routing";
import { translations } from "../../i18n/translations";
import { PresentationProps } from "./Team.types";
import { Link as RouterLink } from "react-router-dom";
import { getRolesDisplayNames } from "../../utils/User/getRolesLabel";
import { PersonName } from "../../components/PersonName";
import FullPageContent from "../../components/FullPageContent";

export const TeamPresentation = (props: PresentationProps) => {
  const screen = translations.screens.team;
  const {
    pageTitleRef,
    breadcrumbsRef,
    isLoading,
    list,
    statics,
    onInviteModalOpen,
    onClickUser,
  } = props;

  return (
    <>
      <Layout>
        <Portal containerRef={pageTitleRef}>
          {t<string>(screen.pageTitle)}
        </Portal>
        <Portal containerRef={breadcrumbsRef}>
          <Breadcrumb fontWeight={500}>
            <BreadcrumbItem color="gray.400">
              <BreadcrumbLink as={RouterLink} to={Routing.Root}>
                {t<string>(screen.breadcrumbs.root)}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <div>{t<string>(screen.breadcrumbs.page)}</div>
            </BreadcrumbItem>
          </Breadcrumb>
        </Portal>
        <FullPageContent>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Text fontSize="lg" fontWeight={700}>
              {t<string>(screen.pageTitle)}
            </Text>
            <Button
              colorScheme="blue"
              leftIcon={<PlusIcon />}
              size="sm"
              onClick={onInviteModalOpen}
            >
              {t<string>(screen.add)}
            </Button>
          </Flex>
          {isLoading && (
            <Flex justifyContent="center" mt={4}>
              <Spinner />
            </Flex>
          )}

          <br />
          {!isLoading && (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>{t<string>(screen.table.teamMember)}</Th>
                    <Th>{t<string>(screen.table.role)}</Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.scheduled)}
                    </Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.inProgress)}
                    </Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.completed)}
                    </Th>
                    <Th textAlign="center">
                      {t<string>(screen.table.avgRating)}
                    </Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {(list || []).map((item) => (
                    <Tr
                      key={item.userId}
                      _hover={{
                        backgroundColor: "gray.50",
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      // onClick={onClickUser(item.userId)}
                    >
                      <Td onClick={onClickUser(item.userId)}>
                        <PersonName
                          email={item.email}
                          firstName={item.fullName}
                          lastName=""
                          profilePicUrl={item.photoUrl}
                        />
                      </Td>
                      <Td onClick={onClickUser(item.userId)}>
                        {getRolesDisplayNames(item.roles, statics).join(", ")}
                      </Td>
                      <Td textAlign="center" onClick={onClickUser(item.userId)}>
                        {item.scheduledJobs}
                      </Td>
                      <Td textAlign="center" onClick={onClickUser(item.userId)}>
                        {item.inProgressJobs}
                      </Td>
                      <Td textAlign="center" onClick={onClickUser(item.userId)}>
                        {item.completedJobs}
                      </Td>
                      <Td textAlign="center" onClick={onClickUser(item.userId)}>
                        ★ {item.userRating || "-"}
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            aria-label="Options"
                            as={IconButton}
                            icon={<DotsIcon />}
                            variant="ghost"
                          />
                          <MenuList>
                            <MenuItem onClick={onClickUser(item.userId)}>
                              {t<string>(screen.table.viewProfile)}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
          {!isLoading && !(list || []).length && (
            <Flex justifyContent="center" mt={4}>
              <Text fontWeight={500}>{t<string>(screen.noTeamMembers)}</Text>
            </Flex>
          )}
        </FullPageContent>
      </Layout>
    </>
  );
};
