import { Endpoints } from "../../../constants/endpoints";
import client from "../../client";
import { TUpdatePassword, TUpdatePasswordBody } from "./types";

export const putUpdatePassword = async (payload: TUpdatePasswordBody) => {
  const { data } = await client.put<TUpdatePasswordBody, TUpdatePassword>(
    Endpoints.postUpdatePassword,
    {
      ...payload,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
