import { Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { translations } from "../../../../ ../../../../i18n/translations";

export const ResultStep = () => {
  const screen =
    translations.screens.pumperPanel.truck.createTruckModal.resultStep;
  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <br />
      <Text fontSize="xl" fontWeight={600}>
        {t<string>(screen.success)}
      </Text>
      <br />
      <Text textAlign="center">{t<string>(screen.successMassage)}</Text>
      <br />
    </Flex>
  );
};
