import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { PersonName } from "../PersonName";
import { t } from "i18next";
import { translations } from "../../i18n/translations";
import { PatternFormat } from "react-number-format";
import { PHONE_PATTERN } from "../../constants/global";

type Props = {
  firstName: string;
  lastName: string;
  email?: string | null;
  phone?: string | null;
  profilePicUrl?: string | null;
};

export const PersonPopover = (props: Props) => {
  const { firstName, lastName, email, phone, profilePicUrl } = props;
  const screen = translations.common.personPopover;
  return (
    <Popover key={email}>
      <PopoverTrigger>
        <Flex style={{ cursor: "pointer" }}>
          <PersonName
            email={email}
            firstName={firstName}
            lastName={lastName}
            profilePicUrl={profilePicUrl}
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex flexDirection="column" p={2}>
            <PersonName
              email={email}
              firstName={firstName}
              lastName={lastName}
              profilePicUrl={profilePicUrl}
            />
            <Flex mt={3}>
              <Text fontWeight="600">{t<string>(screen.email)}</Text>
              <Flex ml={2}>
                {email ? (
                  <a href={`mailto:${email}`}>
                    <Text whiteSpace="break-spaces">{email}</Text>
                  </a>
                ) : (
                  "-"
                )}
              </Flex>
            </Flex>
            <Flex mt={2}>
              <Text fontWeight="600" whiteSpace="break-spaces">
                {t<string>(screen.phone)}
              </Text>
              <Flex ml={2}>
                {phone ? (
                  <a href={`tel:${phone}`}>
                    <Text>
                      <PatternFormat
                        displayType="text"
                        format={PHONE_PATTERN}
                        value={phone}
                      />
                    </Text>
                  </a>
                ) : (
                  "-"
                )}
              </Flex>
            </Flex>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
