import { Endpoints } from "../../../constants/endpoints";
import { TDumpingLogRaw } from "./types";
import client from "../../client";

export const getDumpingLogs =
  (companyId: number, startDate: string, endDate: string) => async () => {
    const { data } = await client.get<TDumpingLogRaw>(
      Endpoints.getDumpingLog(companyId!, startDate, endDate)
    );
    return data;
  };
