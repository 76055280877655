import {
  Flex,
  Image,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Spinner,
  Box,
} from "@chakra-ui/react";
import ReportImg from "../../assets/report.png";
import PdficonImage from "../../assets/pdficon.png";
import React, { useState } from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import { TJob } from "../../../../api/modules/Jobs/types";
import { ReportType } from "../../Job.types";
import { getFile } from "../../../../api/client/client";
import { Endpoints } from "../../../../constants/endpoints";
import {
  AiOutlineLink as LinkIcon,
  AiOutlineCloudDownload as DownloadIcon,
} from "react-icons/ai";

type Props = {
  jobId?: number;
  status?: string;
  job?: TJob;
};

export const JobReport = (props: Props) => {
  const screen = translations.screens.job;
  const { jobId, status, job } = props;
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  if (!jobId || !status) return null;

  const generatedReports = job?.generatedReports;
  const isReportAvailable = status.toLowerCase() === "completed";
  const descriptionVisible = [
    "draft",
    "available",
    "accepted",
    "in progress",
    "in-progress",
    "paid",
  ].includes(status.toLowerCase());

  const getReportTypeName = (type: ReportType): string => {
    switch (type) {
      case ReportType.SepticFacts:
        return "SepticFax Report";
      case ReportType.Title5Inspection:
        return "Title 5 Inspection Report";
      case ReportType.Pumpout:
        return "System Pumping Report";
      case ReportType.SepticPumpout:
        return "Septic Facts Pumping Report";
      default:
        return "";
    }
  };

  const openPDFInNewTab = (url: string) => {
    const newTab = window.open();
    if (newTab) {
      newTab.location.href = url;
    }
  };

  const downloadReport = async (url: string, errorMsg: string) => {
    setIsLoading(true);
    setDisabled(true);
    const downloadedFileRes = await getFile(url);
    if (downloadedFileRes) {
      const fileURL = window.URL.createObjectURL(downloadedFileRes);
      openPDFInNewTab(fileURL);
    } else {
      toast({
        title: "Error",
        description: t<string>(errorMsg),
        status: "error",
        position: "top-right",
      });
      console.error("Error: Blob is null or empty.");
    }
    setIsLoading(false);
    setDisabled(false);
  };

  const onClickJob = (type: ReportType) => async () => {
    if (disabled) return;
    switch (type) {
      case ReportType.SepticFacts:
        await downloadReport(
          Endpoints.getSepticFactsReport(jobId),
          translations.errors.septic_facts_report_error
        );
        break;
      case ReportType.Title5Inspection:
        await downloadReport(
          Endpoints.getTitle5InspectionReport(jobId),
          translations.errors.title5_inspection_report_error
        );
        break;
      case ReportType.Pumpout:
        await downloadReport(
          Endpoints.getSystemPumpingReport(jobId),
          translations.errors.system_pumping_report_error
        );
        break;
      case ReportType.SepticPumpout:
        await downloadReport(
          Endpoints.getSepticPumpoutReport(jobId),
          translations.errors.septic_pumpout_report_error
        );
        break;
    }
  };

  const copyToClipboard = (reportUrl: string) => {
    if (reportUrl) {
      navigator.clipboard.writeText(reportUrl).then(() => {
        toast({
          title: "Success",
          description: "URL copied to clipboard",
          position: "top-right",
          status: "success",
          isClosable: true,
          variant: "subtle",
          containerStyle: { color: "#067647" },
        });
      });
    }
  };

  return (
    <Flex
      alignItems="normal"
      flexDirection="column"
      justifyContent="stretch"
      textAlign="center"
      width="100%"
    >
      {!isReportAvailable && (
        <>
          <br />
          <br />
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="stretch"
            textAlign="center"
            width="100%"
          >
            <Image src={ReportImg} width="150px" />
          </Flex>
          <br />
          <Text fontWeight="600" size="xl">
            {t<string>(screen.noReport)}
          </Text>
          {descriptionVisible && (
            <Text>{t<string>(screen.noReportDescription)}</Text>
          )}
        </>
      )}
      {isReportAvailable && (
        <TableContainer>
          {isLoading && (
            <Flex
              left="50%"
              position="fixed"
              top="53%"
              transform="translate(-50%, -50%)"
              zIndex="9999"
            >
              <Spinner />
            </Flex>
          )}
          <Table>
            <Thead>
              <Tr>
                <Th>{t<string>(screen.reportTable.report)}</Th>
                <Th>{t<string>(screen.reportTable.address)}</Th>
                <Th>{t<string>(screen.reportTable.date)}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {generatedReports?.map(
                ({
                  generatedReportId,
                  reportGeneratedDateTime,
                  reportTypeId,
                  reportUrl,
                }) => (
                  <Tr
                    key={generatedReportId}
                    _hover={{
                      backgroundColor: "gray.50",
                    }}
                    style={{
                      cursor: disabled ? "not-allowed" : "pointer",
                      pointerEvents: disabled ? "none" : "auto",
                    }}
                    onClick={onClickJob(reportTypeId as ReportType)}
                  >
                    <Td fontWeight={500}>
                      <Flex alignItems="center" width="250px">
                        <Image
                          key={generatedReportId}
                          src={PdficonImage}
                          width="40px"
                        />
                        <Flex ml={2} whiteSpace="break-spaces">
                          <Text>
                            {getReportTypeName(reportTypeId as ReportType)}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>
                    <Td>
                      <Text fontWeight="medium">
                        {job?.projectAddressStreet}
                      </Text>
                      {job?.projectAddressCity || ""}{" "}
                      {job?.projectAddressState || ""}{" "}
                      {job?.projectAddressZip || ""}
                    </Td>
                    <Td>{reportGeneratedDateTime}</Td>
                    <Td>
                      <Flex flexDirection="row" gap={3}>
                        <Box
                          left="10px"
                          style={{
                            cursor: disabled ? "not-allowed" : "pointer",
                            pointerEvents: disabled ? "none" : "auto",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!disabled) {
                              onClickJob(reportTypeId as ReportType)();
                            }
                          }}
                        >
                          <DownloadIcon fontSize="22px" />
                        </Box>
                        <Box
                          left="10px"
                          onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(reportUrl);
                          }}
                        >
                          <LinkIcon fontSize="22px" />
                        </Box>
                      </Flex>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
};
