import { Flex, Text, Image } from "@chakra-ui/react";
import React from "react";
import { translations } from "../../../../i18n/translations";
import { t } from "i18next";
import SuccessImage from "../../assets/success.png";
import { PersonName } from "../../../../components/PersonName";
import { TTeamMember } from "../../../../api/modules/Team/types";

type Props = {
  person?: TTeamMember;
};

export const ResultScreen = (props: Props) => {
  const screen = translations.screens.assignJob.resultScreen;
  const { fullName, email, photoUrl } = props.person || {};

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <Image src={SuccessImage} width="150px" />
      <br />
      <Text fontSize="xl" fontWeight={600}>
        {t<string>(screen.title)}
      </Text>
      <br />
      <Text color="gray.500" textAlign="center">
        {t<string>(screen.jobAssigned)}
      </Text>
      <br />
      <PersonName
        email={email}
        firstName={fullName || ""}
        lastName=""
        profilePicUrl={photoUrl}
      />
      <br />
    </Flex>
  );
};
