export type InviteFormType = {
  firstName: string;
  lastName: string;
  email: string;
  roles: (string | number)[];
};

export type TruckFormType = {
  nickName: string;
  licensePlate: string;
  make: string;
  model: string;
  year: number;
  pumpingLicense: string;
  truckId?: number | undefined;
};

export enum PostTruckSteps {
  TruckForm = "truck-form",
  Result = "result",
}
