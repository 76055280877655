import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import {
  MdCorporateFare as CommercialIcon,
  MdHome as ResidentialIcon,
} from "react-icons/md";

type Props = {
  propertyUse: boolean;
};

export const PropertyIcon = (props: Props) => {
  const { propertyUse } = props;
  const IconComponent = propertyUse ? CommercialIcon : ResidentialIcon;
  return (
    <Flex
      alignItems="center"
      backgroundColor="gray.100"
      borderRadius="5px"
      flexShrink={0}
      height="25px"
      justifyContent="center"
      width="25px"
    >
      <Icon as={IconComponent} color="gray.600" />
    </Flex>
  );
};
